@use './mixins' as myTheme;
@use './color' as colors;
@use './typography';
@use './hierarchy';

@-prefix-keyframes slide {
  from {height: 0;}
  to {height: 300px;}
}
.menu-animate {
  animation-name: slide;
  -moz-animation-delay: 3.5s;
  -webkit-animation-delay: 3.5s;
  -o-animation-delay: 3.5s;
  animation-delay: 3.5s;
}
.top-header-container {
  height: var( --top-header-height);
  min-height: var( --top-header-height);
  @include myTheme.theme-aware('background', 'color-background');
  @include myTheme.theme-aware('color', 'color-foreground');
  margin: 0;
  padding: 0;
  .breadcrumb-section {

    flex-grow: 0;
    flex-shrink: 0;
    padding: 0 0 0 48px;

    .navicon {
      margin: 7px 0 3px 0;
      height: calc( (0.4rem + 1vw) / 2 );
      width: calc( (0.1rem + 1vw) / 2 );
    }
  }
  .breadcrumb-row{
    position: relative;
    height: 100%;
  }
  .top-header-menu {
    position: absolute;
    right: 10px;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: flex-end;
    padding: 0 10px 0 0;
    .menu-container {
      width: 48px;
      height: 48px;
      display: flex;
      @extend .white-bg;
      @extend .midnightExpress-icon;
      border-radius: 100%;
      justify-content: center;
      align-self: center;
      cursor: pointer;

      .dropdown-pic-block {
        display: inline-block;
        position: relative;
        align-self: center;
        cursor: pointer;
        @extend .dropdown-text;
        text-transform: capitalize;
      }

      .dropdown-content-block {
        display: none;
        position: absolute;
        top: 70px;
        right:0;
        min-width: 200px;
        overflow: auto;
        z-index: 99;
        @include myTheme.theme-aware('background', 'topMenuPopupContainer-bg');
        box-shadow: 4px 0px 4px #091B27, -4px 0px 12px #091B27;
        border-radius: 4.5px;

        .menu-item {
          padding: calc(1vw - 1px) calc(1vw - 1px);
          border-bottom: 0.5px solid rgba(0, 27, 43, 0.38);
          cursor: pointer;

          &:hover {
            color: #FFFFFF;
            background-color: #00A4BD;
          }

          .menuitemicon {
            margin-right: 0.3rem;
          }
          .text-link, .btn-link {
            display: inline;
            @include myTheme.theme-aware('color', 'anchor-color');
            text-decoration: none;
            @extend .dropdown-text;
            text-transform: capitalize;
            padding: 0;

          }
        }

        .menu-text {
          padding: calc(1vw - 1px) calc(1vw - 1px);//15px 15px;
          span {
            @extend .small-body-text-light;
          }
        }
      }

      .dropdown-content-block-arabic {
        display: none;
        position: absolute;
        top: 77px;
        left: calc(1vw + 2.5rem);
        min-width: 200px;
        overflow: auto;
        z-index: 99;
        @include myTheme.theme-aware('background', 'topMenuPopupContainer-bg');
        box-shadow: 4px 0px 4px #091B27, -4px 0px 12px #091B27;
        border-radius: 4.5px;

        .menu-item {
          padding: calc(1vw - 1px) calc(1vw - 1px);
          border-bottom: 0.5px solid rgba(0, 27, 43, 0.38);
          cursor: pointer;

          &:hover {
            color: #FFFFFF;
            background-color: #00A4BD;
          }

          .menuitemicon {
            margin-right: 0.3rem;
          }
          .text-link, .btn-link {
            display: inline;
            @include myTheme.theme-aware('color', 'anchor-color');
            text-decoration: none;
            @extend .dropdown-text;
            text-transform: capitalize;
            padding: 0;

          }
        }

        .menu-text {
          padding: calc(1vw - 1px) calc(1vw - 1px);//15px 15px;
          span {
            @extend .small-body-text-light;
          }
        }

      }




    }
  }
  .menu-container {
    position: relative;
  }

  .menu-container::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    margin-right: 5px;
    border-width: 13px 0 0 10px;
    transform: rotate(-90deg);
    border-color: transparent transparent transparent #fff;
  }
  .logo-block {
    width: 18%;
    padding: 16px 16px 16px 28px;
    height: 100%;
    box-sizing: border-box;

    [class^="icon-"] {
      color: #FFF;
      font-size: 17px;
    }
    .logo{
      width:120px;
      height: auto;
      cursor: pointer;
    }
    .logoriyadh{
      width:150px;
      height: 50px;
      cursor: pointer;
    }

    .btn-close {
      margin: 10px 10px 0 0;
      width: 30px;
      height: 30px;
      outline: none;
      padding: 0.25em 0 0.25em 0;
      background: none;
      border: none;

      &:active, &:hover, &:focus {
        outline: none;
        border: none;
        box-shadow: none;
      }
    }
  }
}
