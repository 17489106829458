.lato {
    font-family: 'Lato', sans-serif;
    /* font-style: normal; */
}

.fontLight {
    @extend .lato;
    font-weight: 300;
}

.fontRegular {
    @extend .lato;
    font-weight: 400; 
}
.fontRegular-subscript {
    @extend .lato;
    font-weight: 100; 
}
.fontMedium {
    @extend .lato;
    font-weight: 500;
}
.fontMedium_cess {
    @extend .lato;
    margin-left: 1.3%;
    font-weight: 300;
}

.fontSemibold {
    @extend .lato;
    font-weight: 600;  
}

.fontExtrabold {
    @extend .lato;
    font-weight: 800; 
}
