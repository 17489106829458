@use './inner/mixins' as myTheme;
@use './inner/color' as colors;
@use './inner/hierarchy';
@use './inner/typography';

button {
  background-color: transparent;
  background-image: none;
}

*,
::before,
::after {
  box-sizing: border-box;
  /* 1 */
  border-width: 0;
  /* 2 */
  border-style: solid;
  /* 2 */
  border-color: #e5e7eb;
  /* 2 */
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 15px;
}

@media print {
  body * {
    visibility: hidden; 
  }

  #print_content, #print_content * {
    visibility: visible; 
    color: black !important; 
    background-color: transparent !important; 
    text-decoration: none !important; 
  }
}

.edit-input {
  padding: 3%;
  box-sizing: border-box;
  width: 200px;
  min-height: 2.35rem;
  @include myTheme.theme-aware('background', 'formInputColor-bg');
  @include myTheme.theme-aware('color', 'formInputColor-text');
  border: 0.75px solid rgba(255, 255, 255, 1);
  box-shadow: 2px 0px 6px rgb(0 0 0 / 2%), 0px 2px 6px rgb(0 0 0 / 2%);
  border-radius: 3px;
  resize: none;
  margin: 0.7rem 0rem;
}
.edit-input.fullWidth{
  width: 100%;
  padding: 1rem !important;
}

.edit-input-mealtext{
  @extend .edit-input;
  width: 100px;
  min-width: 90px;
}
.trollyQtyInp {
  width: 100px;
}

.edit_input_galley {
  padding: 0 3% 0 3%;
  box-sizing: border-box;
  width: 100%;
  min-height: 40px;
  margin-top: 0.3rem;
  background-color: #dde0e0;
  border: 0.75px solid rgba(255, 255, 255, 1);
  box-shadow: 2px 0px 6px rgb(0 0 0 / 2%), 0px 2px 6px rgb(0 0 0 / 2%);
  border-radius: 2px;
  resize: none;
}

.menu-plannertext {
  @extend .edit-input;
  padding: 2.5%;

}

.input-margin {
  margin: 0.6rem 0rem;
}

.edit-input-textarea {
  @extend .edit-input;
  max-width: 910px;
  min-width: 710px;
}
.edit-input-meals {
  @extend .edit-input;
  width: 380px;
  min-width: 330px;
  min-height: 70px;
}
.edit-input-meals-desc {
  @extend .edit-input;
  width: 522px;
  min-width: 510px;
  min-height: 70px;
}


.edit-input-galley1 {
  @extend .edit-input;
  width: 270px;
}

.edit-input::placeholder {
  color: #808080;
}

.sector-details {
  color: #808080;
  margin-left: 8px;
}

.column {
  float: left;
  width: 25%;
}

.sales_stat {
  float: left;
  width: 35%;
}


.middle {
  width: 50%;
}

.sales_quartermain {
  width: 40%;
  margin-right: 5rem;
  display: inline-block;

}

.sales_quarter_second {
  width: 35%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}

.vertical_spereator {
  border-left: 1.195px solid #FFFFFF;
  height: 30%;
  position: absolute;
  width: 258.24px;
  left: 25%;
  top: 500px;
  opacity: 0.07;
}

.order_numbers {
  font-size: 20px;
  color: #63ABFD;
}

.sales_main_desciption {
  background-color: #0D2636;
}


.row:after {
  margin: 23px 0px 23px 0px;
  content: "";
  display: table;
  clear: both;
}


.wide-textarea-input {
  .edit-input {
    width: 100%;
    resize: vertical;
    padding: 3%;
  }
}

.edit-input-galley {
  @extend .edit-input;
  margin-top: 10px;
  width: 60%;
  float: right
}

.edit-input-galley-aircraft {
  @extend .edit-input-galley;
  margin-right: 1.5rem;
  width: 185px;
  float: left
}
.edit-input-create-ac{
  @extend .edit-input-galley;
  margin-right: 1.5rem;
  width: 200px;
  float: left;
  margin: 0.5rem 0 !important;
}

.edit-input-inv {
  @include myTheme.theme-aware('background', 'formInputColor-bg');
  @include myTheme.theme-aware('color', 'formInputColor-text');
  padding: 0% 3% 0 10%;
  box-sizing: border-box;
  width: 11.5rem;
  min-height: 2.6rem;
  margin-left: 3rem;
  border: 0.75px solid rgb(255, 255, 255);
  box-shadow: 2px 0px 6px rgb(0 0 0 / 2%), 0px 2px 6px rgb(0 0 0 / 2%);
  border-radius: 5px;
  resize: none;
}

.edit-input-inv::placeholder {
  color: #FFFFFF;
}

.edit-input-inv-stock {
  @include myTheme.theme-aware('background', 'formInputColor-bg');
  @include myTheme.theme-aware('color', 'formInputColor-text');
  padding: 0% 3% 0 10%;
  box-sizing: border-box;
  width: 11.5rem;
  min-height: 2.6rem;
  margin-left: 1.5rem;
  border: 0.75px solid rgb(255, 255, 255);
  box-shadow: 2px 0px 6px rgb(0 0 0 / 2%), 0px 2px 6px rgb(0 0 0 / 2%);
  border-radius: 5px;
  resize: none;
}

.edit-input-inv-stock::placeholder {
  color: #FFFFFF;
}

.wrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
  width: 100%;
  padding: 15px;
  border: 2px solid #bce8f1;
  border-radius: 5px;
  color: #31708f;
  margin-top: 10px;
  margin-left: 30px;
  background: #d9edf7;
  text-align: left;
}

.wrapper-sector {
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
  width: 100%;
  padding: 20px;
  border: 2px solid #bce8f1;
  border-radius: 5px;
  color: #31708f;
  margin-left: 30px;
  background: #d9edf7;
  text-align: left;
}

.wrapper-sector-dynamic {
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
  width: 100%;
  border-radius: 5px;
  color: #31708f;
  text-align: left;
}

.chart_sales {
  width: 55%;
  height: 25%;
}

.dialog__description__checkbox>input[type="checkbox"],
.checkbox__dashboard>input[type="checkbox"] {
  --checked-border: #ffffff;
  --checked-background: #ffffff;
  --checked-box-border: 0.75px solid #ffffff;

  position: relative;
  margin-top: 10px;
  margin-left: 15px;
  margin-right: 15px;
  padding: 0 0.5% 0 0.5%;
  width: 19.23px;
  height: 19.23px;
  color: #162f3f;
  border: 0.75px solid #ffffff;
  border-radius: 2px;
  appearance: none;
  outline: 0;
  cursor: pointer;
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);

  &::before {
    position: absolute;
    content: '';
    display: inline-block;
    top: 4px;
    left: 7px;
    width: 4px;
    height: 9px;
    border-style: solid;
    border-color: #162f3f;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
  }

  &:checked {
    color: #162f3f;
    border-color: var(--checked-border);
    background: var(--checked-background);
    border: var(--checked-box-border);

    &::before {
      opacity: 1;
    }

    ~label::before {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
  }
}

.dialog__description__checkbox>input[type="checkbox"]:disabled,
.checkbox__dashboard>input[type="checkbox"]:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.checkbox__dashboard>input[type="checkbox"] {
  --checked-border: #09C0D7;
  --checked-background: #09C0D7;
  --checked-box-border: 0.75px solid #9C9C9C;
}

.selectAll-checkbox-size>input[type="checkbox"]{
  width:16.65px;
  height:16.65px;
}

.dialog__description__radio {
  input[type="radio"] {
    position: absolute;
    opacity: 0;
  }

  .dialog_buttons {
    cursor: pointer;
    display: inline-block;
  }

  .control-indicator {
    top: 12px;
    left: 8px;
    height: 23px;
    width: 23px;
    min-width: 23px;
    min-height: 23px;
    margin-top: 5px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    border: 0.75px solid #ffffff;
    border-radius: 50%;
  }

  .control-indicator:after {
    content: '';
    display: block;
    left: 10px;
    top: 10px;
    height: 15px;
    width: 15px;
    display: flex;
    margin-top: 3px;
    justify-content: center;
    min-width: 15px;
    min-height: 15px;
    border-radius: 50%;
  }

  .control input:checked~.control-indicator {
    border-color: #ffffff;
  }

  .control input:checked~.control-indicator:after {
    background-color: #ffffff;
  }
}

.upload-align {
  margin-left: 7%;
}

.upload-msg {
  .alert {
    width: 80%;
    text-align: left;
  }

  .key {
    margin-right: 16px;
  }
}


.select-input {
  margin: 0.7rem 0rem;
  padding: 3%;
  box-sizing: border-box;
  width: 200px;
  min-height: 2.35rem;
  border: 0.75px solid rgba(255, 255, 255, 1);
  box-shadow: 2px 0px 6px rgb(0 0 0 / 2%), 0px 2px 6px rgb(0 0 0 / 2%);
  resize: none;
  border-radius: 3px;
  -moz-appearance: none;
  -webkit-appearance: none;
  @include myTheme.theme-aware('background', 'formInputColor-bg-select');
  @include myTheme.theme-aware('color', 'formInputColor-text');

  background-position: right 10px top 50%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.salesPageModal {
  .select-input {
    width: 200px;
  }

  .dialog__description__checkbox {
    padding-top: 0rem !important;
    padding-bottom: 1rem;
  }
}

.select-input-aircraft {
  @extend .select-input;
  margin: 0.6rem .46rem;
  width: 202.4px;
}
.select-input-create-ac {
  @extend .select-input;
  width: 200px;
  margin: 0.5rem 0 !important;
}

.select-input-conflict-fix {
  @extend .select-input;
  width:1rem;
  cursor: pointer;
  appearance: none;
  @include myTheme.theme-aware('background', 'galleyRemoveButtonColor-bg');
  @include myTheme.theme-aware('color', 'formInputColor-text');
}

.select-input-dashboard-list {
  @extend .select-input;
  width: 210px;
  min-height: 20px;
}

.select-input-galley1 {
  @extend .select-input;
  width: 17.14rem;
}

.select-input-vendor {
  @extend .select-input;
  margin-left: 5.5rem;
}

.modal-overlay-bg2 {
  @include myTheme.theme-aware('background', 'modalOverlay2Color-bg');
}

.overlay {
  @include myTheme.theme-aware('background', 'modalOverlayColor-bg');
  overflow: scroll;
  width: inherit;
  padding-bottom: 7.5rem;
  height: 100vh;
  position: fixed;
  z-index: 10;
  top: inherit;
  left: inherit;

  &.sub {
    width: 100%;
    height: 100vh;
    z-index: 11;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
  }
}

.overlay2 {
  @include myTheme.theme-aware('background', 'modalOverlayColor-bg');
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #1b3a48;
  color: white;
  padding: 8px 0px;
}

.header-white {
  top: 0;
  background-color: #1b3a48;
  color: white;
  padding: 8px 0px;
}

.sticky-galley {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.overlay_dashboard {
  overflow: scroll;
  width: inherit;
  position: fixed;
  top: 5vh !important;
  padding-bottom: 7.5rem;
  height: 100vh;
  position: fixed !important;
  z-index: 10;
  top: inherit;
  left: inherit;

  &.sub {
    width: 100%;
    height: 100%;
    z-index: 11;
    top: 0;
    left: 0;
  }
}

.pdfContainer {
  position: relative;
  border: 1px dashed #e2e4e6;
  background-color: #1d3f4f;
  margin-left: 0.3rem;
  padding: 0.6rem;
  width: auto;
  border-radius: 5px;
}

.pdfContainer-onload {
  @extend .pdfContainer;
  border: 1px solid#474853
}

.pdf-styles {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #1b3a48;
  color: white;
  padding: 8px 0px;
}

.overlay_galley {
  @extend .overlay;
  overflow: hidden;
  bottom: 1px;
  right: 1px;
}

.overlay_hide {
  @extend .overlay_galley;
}


.dialog {
  --tw-bg-opacity: 1;
  @include myTheme.theme-aware('background', 'modalDialogColor-bg');
  @include myTheme.theme-aware('box-shadow', 'modalDialogShadow');
  margin: 3% 5% 3% 5%;
  max-width: 100%;
  width: 67%;


  .dialog__content {
    padding: 1rem 3% 1.5rem 3%;

    .modal__close_icon {
      cursor: pointer;
      float: right;
      max-width: 2%;
      padding-top: .3rem;
      padding-right: .3rem;
    }

    .permission_selected {
      @extend .modal__close_icon;
      max-width: 4%;
      padding-top: 0rem;
      padding-right: 0rem;
    }

    .role_close_icon {
      position: relative;
      top: -1px;
      display: inline-block;
      margin-left: 7px;
      min-width: 10px;
      padding: 3px 4px;
      color: #fff;
      white-space: nowrap;
      background-color: #162f3f;
      border-radius: 10px;
      font-size: 12px;
      text-align: center;
      font-weight: 700;
      line-height: 1;
    }

    .galley_cart_count {
      @extend .role_close_icon;
      background-color: #0f3b46;
      color: #ffffff;

    }

    .carts_circle {
      border-radius: 10%;
      /* better to use 50% */
      width: 10px;
      height: 10px;
      background: yellow;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }


    .roleModal__remove_icon {

      cursor: pointer;

    }

    .remove_role_icon {
      right: 0;
      top: 10%;
      margin-right: 10px;
      font-size: 2rem;
    }

    .role_card {
      border-radius: 5px;
      border: 1px solid #5f6368;
      box-sizing: border-box;
      display: inline-block;
      font-size: 14px;
      position: relative;
      vertical-align: middle;
    }

    .role_card:hover {
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .dialog__title {
      text-align: center;
      --tw-text-opacity: 1;

      @extend .fontMedium;
      @extend .dialog-title-text;
      /* identical to box height */
      color: rgba(255, 255, 255, 0.87);
    }

    .dialog__title__conditions {
      text-align: left;
      --tw-text-opacity: 1;

      @extend .fontMedium;
      @extend .dialog-title-text;
      /* identical to box height */
      color: rgba(255, 255, 255, 0.87);
    }


    .dialog__alertMessage {
      @include colors.primaryColor;

      margin-top: 1rem;
      padding: 1rem;
      border-radius: 10px;
      text-align: center;
      line-height: 1rem;
      @extend .fontRegular;
    }
.dialog_alert-remove{
  @include colors.uiColor2;
  @extend .dialog__alertMessage;
}

    .dialog__body_container {
      .alert {
        width: 100%;
      }

      .dialog__container_item {

        .dialog__description {
          width: 100%;
          display: flex;
          margin-bottom: 16px;
          flex-direction: column;
          flex-wrap: wrap;
          justify-self: stretch;

          label {
            padding-right: 0.5rem;
          }
        }

        .dialog__description_repl {
          @extend .dialog__description;
          margin-top: 1.1rem;

        }

        .dialog__description_config {
          @extend .dialog__description;
          margin-bottom: 2rem;
        }

        .dialog__description__checkbox {
          width: 100%;
          display: flex;
          padding-top: 1rem;
          padding-bottom: 1rem;
          align-items: center;
          flex-wrap: wrap;
          justify-self: stretch;
          flex-direction: row;
        }

        .dialog__description__checkbox__reset {
          @extend .dialog__description__checkbox;
          padding-bottom: 2.5rem;
        }
      }
    }

  }

  .dialog__content_salescard {
    @extend .dialog__content;
    background: #0D2636;
    border-radius: 4px;
  }

  .role_name {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  .dialog__statusBlock {
    display: flex;
    justify-content: center;
    margin: 2rem 2rem 0.1rem 2rem;
    padding: 0.7rem 1rem 1rem 1rem;
    border-radius: 10px;
    font-size: 1rem;

    &.alert_error {
      @include colors.uiColor2;
    }

    &.alert_success {
      @include colors.primaryColor3;
    }
  }

  .dialog__footer {
    padding-top: 2.478rem;
    padding-bottom: 1rem;
  }

}

.dialog2 {
  --tw-bg-opacity: 1;
  @include myTheme.theme-aware('background', 'modalDialogColor-bg');
  @include myTheme.theme-aware('box-shadow', 'modalDialogShadow');
  margin: 3% 5% 3% 5%;
  max-width: 50%;
  min-width: 50%;
  width: 100%;

  .dialog__header {
    padding: 0rem 3% 0rem 3%;
  }

  .modal__close_icon {
    cursor: pointer;
    float: right;
    max-width: 2%;
    padding-top: .3rem;
    padding-right: .3rem;
  }

  .dialog__title {
    text-align: center;
    --tw-text-opacity: 1;

    @extend .fontMedium;
    @extend .dialog-title-text;
    /* identical to box height */
    color: rgba(255, 255, 255, 0.87);
  }

  .dialog__content {
    padding: 1rem 3% 1.5rem 3%;
    display: flex;
    justify-content: center;


    .dialog__alertMessage {
      @include colors.primaryColor;

      margin-top: 1rem;
      padding: 1rem;
      margin-left: 20.3rem;
      margin-right: 20.3rem;
      border-radius: 10px;
      text-align: center;
      line-height: 1rem;
      @extend .fontRegular;
    }

    .dialog__body_container {
      .alert {
        width: 100%;
      }

      .dialog__container_item {

        .dialog__description {
          width: 100%;
          display: flex;
          margin-bottom: 16px;
          flex-direction: column;
          flex-wrap: wrap;
          justify-self: stretch;

          label {
            color: rgba(255, 255, 255, 0.87);
            padding-right: 0.5rem;
          }
        }

        .dialog__description_repl {
          @extend .dialog__description;
          margin-top: 1.1rem
        }

        .dialog__description_config {
          @extend .dialog__description;
          margin-bottom: 2rem;
        }

        .dialog__description__checkbox {
          width: 100%;
          display: flex;
          padding-top: 1rem;
          padding-bottom: 1rem;
          align-items: center;
          flex-wrap: wrap;
          justify-self: stretch;
          flex-direction: row;
        }

        .dialog__description__checkbox__reset {
          @extend .dialog__description__checkbox;
          padding-bottom: 2.5rem;
        }
      }
    }

  }

  .dialog__statusBlock {
    display: flex;
    justify-content: center;
    margin: 2rem 2rem 0.1rem 2rem;
    padding: 0.7rem 1rem 1rem 1rem;
    border-radius: 10px;
    font-size: 1rem;

    &.alert_error {
      @include colors.uiColor2;
    }

    &.alert_success {
      @include colors.primaryColor3;
    }
  }

  // .dialog__footer {
  //   padding-top: 2.478rem;
  //   padding-bottom: 1rem;
  // }

}

.dialog-invoice {
  @extend .dialog;
  margin: 0;
  padding: 0;
  width: 80%;
}

.confirmation_popup {
  display: flex;
  width: 40%;
  justify-content: center;
  align-items: center;
  margin: 2rem auto 0.1rem auto;
  padding: 0.7rem 1rem 1rem 1rem;
  border-radius: 10px;
  font-size: 1rem;

  &.alert_error {
    @include colors.uiColor2;
  }

  &.alert_success {
    @include colors.primaryColor3;
  }
}

.confirmation_popup_galley {
  @extend .confirmation_popup;
  width: 40%;
  white-space: unset;

  &.alert_error {
    @include colors.uiColor2;
  }

  &.alert_success {
    @include colors.primaryColor3;
  }
}

.confirmation_popup_login {
  @extend .confirmation_popup;
  width: 90%;
  max-width: 24.6rem;
  border-radius: 2px;
  padding: 0.3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 2rem 2rem 0.1rem 0.45rem;

}

.confirmation_popup_dfp,
.confirmation_popup_dfp_context {
  padding: 0.5rem;
  margin: 0.5rem 2rem;
  border-radius: 5px;
  &.alert_success {
    @include colors.primaryColor3;
  }
  &.alert_error {
    @include colors.uiColor2;
  }
}


.confirmation_popup_dfp {
  margin-top: 2rem;
}

.dialog_galley_load {
  @extend .dialog;
  --tw-bg-opacity: 1;
  margin: 10% 5% 5% 10%;
  max-width: 80%;
  width: 80%;
}

.dashboard_enlarge {
  @extend .dialog;
  border-radius: 4px;
}

.dialog-extended {
  @extend .dialog;
  display: flex;
  align-items: center;
  width: 63%;
  height: 100vh;
  position: absolute;
  z-index: 1000;
}

.image__close__icon {
  cursor: pointer;
  max-width: 2%;
  padding-top: 0.3rem;
  padding-right: 0.1rem;
  margin-left: 2rem;
}

.formGroup__formHorizontal {
  padding: 3rem;
}

.font-weight-margin {
  font-size: 1.23rem;
  margin-top: 2.5rem;
}

.img-thumbnail {
  max-width: 100%;
  height: auto;
  padding: 4px;
  line-height: 1.42857143;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.pvm__text-center {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  color: #FFFFFF;
}

.col-xs-11 {
  display: block;
  width: 91.66666667%;
  float: left;
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
}

.list-group {
  margin-bottom: 20px;
  padding-left: 0;
  width: 100%;
}

.list-group-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: #162f3f;
  border: 1px solid #ddd;
  color: #ffff;
}

.list-group-item-role {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: #162f3f;
}

.galley-modal:hover {
  cursor: pointer;
}

.list-group-item:hover {
  cursor: pointer;
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: #ffff;
  border: 1px solid #ddd;
  color: #162f3f;
}

.list-group- {
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 20px;

}

.qr_code {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  padding-left: 3px;
}

.ul-class {
  margin-block-start: 0em;
  margin-block-end: 0em;
  padding-inline-start: 0px;
  padding: 0rem 1rem 0 1rem;

  li {
    font-size: 14px;
  }
}

#file-upload {
  opacity: 0;
}

.update-password {
  border: 1px solid #0a2434;
  border-radius: 5px;
  padding-top: 10px;
  color: #ffff;
  background-color: #0a2434;
}


.checkmark-roles {
  flex-direction: row;
  float: right;
  content: "\2714";
  color: #70CEEA;
  font-weight: bold;
  font-size: 1.2rem;
  transform: scale(1.5);
}

.passwordReset_body-input {
  margin: 0 20% 12% 20%;
}

.passwordReset_body {
  margin: 0 20%;
}

.btn-file {
  width: 20%;
  height: 60%;
  z-index: 0 !important;
}

.btn-file.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

.form-control {
  height: 60%;
}

.input-group {
  width: 60% !important;
}

@media (min-width: 640px) {}

@media (min-width: 768px) {}

@media (min-width: 1024px) {}

@media (min-width: 1280px) {}

@media (min-width: 1536px) {}

/**
    Search-select
*/
.select-search-container {
  --select-search-background: transparent;
  --select-search-border: #dce0e8;
  --select-search-selected: #1e66f5;
  --select-search-text: #000;
  --select-search-subtle-text: #6c6f85;
  --select-search-inverted-text: var(--select-search-background);
  --select-search-highlight: #eff1f5;
  --select-search-font: 'Inter', sans-serif;

  width: inherit;
}

.select-search-input {
  // position: relative;
  // z-index: 1;
  display: block;
  outline: none;

  /* Custom */
  text-align: left;
  text-overflow: ellipsis;
  letter-spacing: 0.01rem;
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;

  // margin: 0.4rem 0rem;
  padding: 0 3% 0 3%;
  box-sizing: border-box;
  width: 200px;
  height: 40px;
  @include myTheme.theme-aware('background', 'formInputColor-bg');
  @include myTheme.theme-aware('color', 'formInputColor-text');
  border: 0.75px solid rgba(255, 255, 255, 1);
  box-shadow: 2px 0px 6px rgb(0 0 0 / 2%), 0px 2px 6px rgb(0 0 0 / 2%);
  border-radius: 2px;
  font-family: inherit; //var(--select-search-font);
  font-size: inherit;
  letter-spacing: inherit; //0.01rem;
  margin: 0.6rem 0rem;
}

.select-search-is-selected {
  font-weight: bold;
  color: var(--select-search-selected);
  @include myTheme.theme-aware('background', 'primaryColor2-bg');
}

.select-search-is-highlighted,
.select-search-option:not(.select-search-is-selected):hover {
  @include myTheme.theme-aware('background', 'primaryColor2-bg');
}

.select-search-select {
  box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
  overflow: auto;
  max-height: 360px;

  @include myTheme.theme-aware('background', 'formInputColor-bg');
  border: 0.75px solid rgba(255, 255, 255, 1);

  // .select-search-options {
  //   list-style-type: none;

  //   .select-search-row {
  //     list-style-type: none;

  //   }
  // }
}

.select-search-option,
.select-search-not-found {
  display: block;
  width: 100%;
  padding: 0 0px;
  border: none;
  outline: none;
  font-family: inherit; //var(--select-search-font);
  font-size: inherit;
  text-align: left;
  letter-spacing: inherit; //0.01rem;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;

  @include myTheme.theme-aware('background', 'formInputColor-bg');
  @include myTheme.theme-aware('color', 'formInputColor-text');
}

.collapsable {
  .container {
    overflow: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
    max-height: 0;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);

    &.open {
      max-height: 100vh;
      transition: max-height 1s ease-in-out;
    }
  }
}

.spreadsheet-modal {
  .btn {
    margin-bottom: 0;
    margin-right: 8px;
  }
}

.spread-sheet-container {
  .cell-alert {
    margin-bottom: 0;
    color: #dc3545;

    &.success {
      color: #31708f;
    }
  }
}

.spread-sheet {
  max-height: 40vh;
  overflow: auto !important;
  margin: 16px 0;


  tr:first-child .Spreadsheet__header:first-child {
    min-width: 50px;
    width: 50px;
  }

  tr:first-child th {
    position: sticky;
    top: 0px;
    background-color: #5f5f5f;
    color: #FFF;
    z-index: 2;
    text-align: left;
  }

  .data-viewer {
    width: 100%;
    height: 100%;

    &.error::after {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      border-bottom: 4px solid transparent;
      border-left: 4px solid transparent;
      border-top: 4px solid crimson;
      border-right: 4px solid crimson;
    }
  }

  .Spreadsheet__cell {
    position: relative;
  }

  .data-editor {
    .Spreadsheet__cell {
      padding: 0;
    }

    input {
      width: 100%;
      height: 100%;
      background-color: transparent;
      border: none;
      color: #000;

      &:active,
      &:focus {
        outline: none;
      }
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }

    .drop-down {
      position: absolute;
      z-index: 800;
      top: 100%;
      left: 0;
      min-width: 100%;
      margin-top: 1px;
      max-height: 250px;
      overflow: hidden auto;
      cursor: default;
      -webkit-box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
      box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
      background-color: #fff;
      padding-left: 0;

      li {
        list-style: none;
        list-style-type: none;
        user-select: none;
        font-size: 12px;
        line-height: 22px;
        white-space: nowrap;
        padding: 0 4px;
        color: #333;
      }
    }
  }
}

.trolley-item-add {
  padding-left: 9%;
  width: 1rem;
  min-height: 1rem;
  box-sizing: border-box;
  border: 0.8px solid rgb(255, 255, 255);
  box-shadow: 2px 0px 6px rgb(0 0 0 / 2%), 0px 2px 6px rgb(0 0 0 / 2%);
  border-radius: 15px;
  resize: none;
}

.trolley-detail {
  padding: 0% 3% 0 10%;
  box-sizing: border-box;
  width: 11rem;
  min-height: 7rem;
  border: 0.75px solid rgb(255, 255, 255);
  box-shadow: 2px 0px 6px rgb(0 0 0 / 2%), 0px 2px 6px rgb(0 0 0 / 2%);
  border-radius: 5px;
  resize: none;
}

.galley-carts {
  @extend .trolley-detail;
  padding: 0% 0.3% 0 0.3%;
  background-color: #092333;
  min-width: 12.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trolley-item-add {
  padding-left: 9%;
  width: 1rem;
  min-height: 1rem;
  box-sizing: border-box;
  border: 0.8px solid rgb(255, 255, 255);
  box-shadow: 2px 0px 6px rgb(0 0 0 / 2%), 0px 2px 6px rgb(0 0 0 / 2%);
  border-radius: 15px;
  resize: none;
}

.trolley-table {
  table-layout: fixed;
}

.trolley-plus {
  height: 1.1rem;
  width: 1.1rem;
}

.menuplanner-icons {
  height: 1.5rem;
  width: 1.5rem;
  margin: 5% 5% 0 0%;
  cursor: pointer;

}

.galley-icons {
  @extend .trolley-plus;
  margin: 0 0.2rem;
}

.galley_icons_add {
  @extend .trolley-plus;
  margin: 0.8rem 0.3rem 0.1rem 0rem;
  width: 1.4rem;
  height: 1.4rem;
}

.basestation_del {
  width: 20px;
  cursor: pointer;
  height: 20px;
}

.basestation_edit {
  @extend .basestation_del;
}

.basestation_del:hover {
  color: #F06548;
}

.basestation_edit:hover {
  color: #5f5f5f;
}

.akasaGalleyHalf {
  position: relative;
  right: 0;
  top: 0;
  // left: calc(70.3% - 0.6px);
}

.akasaGalleyFull {
  position: relative;
  right: 0;
  top: 0;
  // left: calc(60% - 0.6px);
}

.grid-container-base {
  display: grid;
  overflow-y: auto;
  height: 76vh;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 20px;
}

.grid-item-base {
  position: relative;
  height: 200px;
  background-color: #1d3e4e;
  border-radius: 3px;
  overflow: hidden;

  .content-base {
    display: flex;
    margin-top: 2rem;
    padding: 20px;
    color: #ffff;
    justify-content: center;
    align-items: center;
  }

  .overlay_base {
    position: absolute;
    bottom: 10;
    left: 0;
    right: 0;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    transform: translateY(100%);
    transition: transform 0.2s ease;
    background-color: transparent;
    background: rgba(12, 206, 234, 0.89);
  }

  &:hover .overlay_base {
    transform: translateY(0);
  }
}

.grid-item-base-add {
  @extend .grid-item-base;
  border: 0.8px solid #ffff;
  background-color: #001b2c;

  .content-base-add {
    @extend .content-base;
  }

  &:hover .content-base-add {
    opacity: 0.4;
  }
}


.no-padding {
  padding: 0 0 0 0 !important;
}


.validationMsgDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  &.error {
    color: #F06548;
  }
}

.listErrorMessagesCls {
  color: #F06548;
  max-height: 10em;
  overflow-y: auto;
  border-radius: 5px;
  background-color: #ffbebe;
  padding: 0.3rem;
}

.disabled-checkbox input[disabled] {
  cursor: not-allowed;
}

.edit-input-employee {
  @extend .edit-input;
  width: 100%;
  padding: 3%
}

.select-employee-designation {
  margin-bottom: 1rem;
}

.device-info-msg{
    margin-bottom: 4px;
    width: 100%;
    padding: 10px;
    border: 1px solid #bce8f1;
    border-radius: 5px;
    color: #31708f;
    background-color: #d9edf7;
}
.seatprice-upload{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
