@use './color-palette' as colorPalettes;

/**
 * theme-aware - Change color of a css property based on the currently active theme
 *
 * @param  {propertyName}     CSS property
 * @param  {searchPropertyKey}   Color name defined in the themes under _color-palette.scss
 *
 * @example - @include theme-aware('background', 'color-background');
 * @returns - background: #FFF;
 */
 @mixin theme-aware($propertyName, $searchPropertyKey) {
    @each $theme-name, $theme-color in colorPalettes.$themes {
        .theme-#{$theme-name} & {
            #{$propertyName}: ( map-get(map-get(colorPalettes.$themes, $theme-name), $searchPropertyKey) #{'!important'} );
        }
    }
}