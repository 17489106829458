
@use './inner/mixins' as myTheme;
@use './inner/color' as colors;
@use './inner/flexclasses';
@use './inner/typography' as typography;
@use './inner/hierarchy';
@use './inner/top-header';
@use "./icon.scss";

body {
  margin: 0px !important;
  font-family: 'Lato', sans-serif;
  overflow: hidden;
  --reducePx: 4px;
  width: 100vw;
  height: 100vh;
  --topBarHeight: 86px;
}


:root {
  --top-header-height: 86px;
}

.side-menu-container {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.full {
  width: 100%;
  height: 100%;
}

.display {
  display: block;
}

.dispNone {
  display: none;
}

.height100 {
  height: 100vh;
}
.height50{
  min-height:3rem;
}

.width90 {
  width: 90%;
}

.width80 {
  width: 80% !important;
}

.full-flex {
  flex: 1;
}

.flex-ctr {
  justify-content: center;
}
.flex-start{
  justify-content: flex-start !important;
}

.hor-ctr {
  align-items: center;
}
.alignItemsEnd{
align-items: flex-end;
}

.space-btn {
  justify-content: space-between;
}
.textAlignCenter{
  text-align: center;
}
.textAlignLeft{
  text-align: left;
}

.width100 {
  width: 100%;
}
.width90 {
  width: 90%;
  margin-left: 3%;
}
.margLeft23{
  margin-left: 3%;
}
.width10{
  width: 10%;
}
.width50 {
  width: 50%;
}
.width40 {
  width: 40%;
}
.width60 {
  width: 60%;
}
.width65 {
  width: 70%;
}

.width20 {
  width: 20%;
}
.width30{
  width:30%;
}
.width25{
  width: 25%;
}

.linkWater-border {
  border: 1px solid #D0D5DD;
}

.solitude-border {
  border: 1px solid #013ca2;
}
.select-dashboard-border{
  border: 1px solid #fff;
}
.fixed-label{
  position: sticky;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-color: #001b2c;
}
.icon {
  height: 1.5rem;
  width: 1.2rem;
}
.add-icon {
  height: 1.5rem;
  width: 1.2rem;
  filter: brightness(0) invert(1);
}

.dashboard-header-icon {
  height: 1rem;
  width: 1rem;
}

.dashboard-header-footer {
  margin-top: 0.0001rem;
  margin-bottom:0;
}


.crew-sales-footer {
  margin-top: auto;
}

.header-footer-text {
  font-weight: 300;
  font-size: small;
}

.dashboard-header-main-icon {
  margin-left: 0.8rem;
  height: 2.4rem;
  width: 2.4rem;
  color: rgba(9, 192, 215, 0.6);
  mix-blend-mode: hard-light;
}

.reports-download{
  font-size:0.87rem;
  width:50%;
  transition: color 0.3s ease;
}
.reports-download:hover{
  cursor: pointer;
color: #70CEEA;;
}

.cursor-disabled {
  cursor: none !important;
}

.edit {
  @extend .icon;
  margin-top: 0.5rem;
}
.editIcon2 {
  @extend .icon;

  img {
    width: 18px;
  }
}
.del {
  @extend .icon;
  // margin-top: 0.26rem;
}


.menu {
  box-shadow: 0px 7.88457px 10.5128px -2.62819px rgba(16, 24, 40, 0.1), 0px 2.62819px 3.94228px -1.31409px rgba(16, 24, 40, 0.05);
}

.profile {
  border-radius: 50%;
  background-color: #dfdfdf;
  width: 32px;
  height: 32px;
}

.pointer {
  cursor: pointer;
}
.scrollHide{
  overflow: hidden;
}
.scrollX {
  overflow-x: auto;
}

.scrollY {
  overflow-y: auto;
}

.marg36 {
  margin: 1.5rem 2.25rem;
}
.marg0{
  margin: 0;
}
.marginHalf{
  margin: 0rem 0.9rem;
}
.margLeft {
  margin-left: 8rem;
}
.margLeft11{
  margin-left:7rem;
}
.margLeft12{
  margin-left:6rem;
}
.margLeft33{
  margin-left:5%;
}
.margLeft160{
  margin-left: 9.3rem;
}
.margLeft40{
  margin-left:40%;
}
.margLeft180{
  margin-left: 6%;

}
.margLeft185{
  margin-left: 6.5%;

}
.margLeft191 {
  margin-left: 8%;
}
.margLeft5 {
  margin-left: 0.3rem;
}
.margLeft6 {
  margin-left: 0.4rem;
}
.margLeft8 {
  margin-left: 0.8rem;
}
.margLeft16 {
  margin-left: 1.8rem;
}
.margLeftSecRow {
  margin-left: 3.5rem;
  margin-bottom: 0.7rem;
}

.margLeft10 {
  margin-left: 0.625rem;
}
.margBottom5{
  margin-bottom: 0.3rem;
}
.margBottom0{
  margin-bottom: 0;
}
.margBottom10{
  margin-bottom: 0.5rem;
}
.margBottm20{
  margin-bottom: 1rem;
}
.margBottom48{
  margin-bottom: 6.5rem;
}
.margBottom18{
  margin-bottom: 2rem;
}
.margBottom28{
  margin-bottom: 2.6rem;
}
.margBottom38{
  margin-bottom: 3.3rem;
}
.margBottom41{
  margin-bottom: 4.3rem;
}
.margLeft19 {
  margin-left: 19.438rem;
}
.margLeft20 {
  margin-left: 5.25rem;
}
.margLeft00{
  margin-left: 0 !important;
}
.margLeft18 {
  margin-left: 4.81rem;
}
.margLeft16{
  margin-left: 1rem;
}
.margRight {
  margin-right: .518rem;
}
.margRight21 {
  margin-right: 25%;
}

.margRight33 {
  margin-right: 40%;
}


.margRight10 {
  margin-right: 0.625rem;
}
.margRight11 {
  margin-right: 1%;
}
.minHeight20{
  min-height: 2.6rem !important;
}
.margRight30 {
  margin-right: 1.875rem;
}

.margRight40{
  margin-right: 3rem;
}

.margRight80 {
  margin-right: 7.455rem;
}
.margRight89 {
  margin-right: 35%;
}

.margTop8 {
  margin-top: 0.5rem;

}
.margTop9 {
  margin-top: 0.57rem;

}
.margTop9-5 {
  margin-top: 0.65rem;

}

.margTop10 {
  margin-top: 0.7rem;

}
.margTop11 {
  margin-top: 0.9rem;

}

.margTop5 {
  margin-top: 0.25rem;

}

.margTop16 {
  margin-top: 1rem;
}
.margTop18{
  margin-top: 1.3rem;
}
.margTop24 {
  margin-top: 1.5rem;
}
.margTop26 {
  margin-top: 1.7rem;
}

.margTop28{
  margin-top: 1.9rem;
}


.margTop36 {
  margin-top: 2.25rem;
}
.margTop40{
  margin-top: 2.85rem;
}
.margTop28 {
  margin-top: 1.8rem;
}
.margTop30 {
  margin-top: 2.15rem;
}

.margTop50 {
  margin-top: 3.125rem;
}

.margBot160 {
  margin-bottom: 10rem;
}

.margBot10 {
  margin-bottom: .625rem;
}

.margBtm16 {
  margin-bottom: 1rem;
}

.margBtm06 {
  margin-top: 0.6rem;
}

.floatRight {
  float: right;
}

.floatLeft {
  float: left;
}
.lineheight15{
  line-height: 1.8;
}
.underline {
  border-bottom: .07rem solid #FFFFFF;
  height: 1.8rem;
}
.underline-grn {
  border-bottom: .07rem solid #FFFFFF;
}

.filter-text-box {
  width: 5.5rem;
  color: #FFFFFF;
  font-size: .9rem;
  background-color: inherit !important;
  border-bottom: .07rem solid #FFFFFF;
  outline: none;
}
.operations-in{
  border: 0.4px solid #fff;
  padding: 7px;
  border-radius: 4px;
}


.asterik-edit{
    position: absolute;
    top: 0;
    right: 0;
    left: 3px;
    color: #000000;
    font-size: 1rem;
}
.loading-dots{
  display: inline;
  align-items: center;
  column-gap: 5px;
}
.loaderdots{
  display: inline-block;
  width: 1.5px;
  height: 1.8px;
  margin: 0px 1px;
  background-color: #fefefe;
  border-radius: 50%;
  animation: scale-up 1000ms var(--d) linear infinite;

}
.loaderdots-button{
  @extend .loaderdots;
  background-color: #000000;

}
@keyframes scale-up{
  25%{
    opacity: 1;
    scale: 1.5;
  }
  50%{
    scale: 1;
  }
}


.all-page-container {
  max-height: 95vh;
  width: auto;
  overflow-y: auto;
  padding: 0px;
}

.container-pdf-view {
  height:95vh;
  width:  auto;
  overflow: hidden;
  margin: 5px;
}

.horizontal-tab-view {
  width: 100%;
  margin-top: 1.5rem;
}

.tab-buttons {
  display: flex;
 border-bottom:3px solid #70CEEA;

}

.tab-buttons button {
  padding: 10px 20px;
  border: none;
  margin: 0.5px;
  font-weight: 400;
  background-color: #1d3e4e;
  color: #ffffff;
  cursor: pointer;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.horizontal-tab-view .tab-buttons button.active {
  background-color: #70CEEA;
  color: #000;
 border-top-left-radius: 5px;
 border-top-right-radius: 5px;
 margin: 0px 0.5px;

}

.tab-content {
  margin-top: 1.5rem;
  &::-webkit-scrollbar {
    width: 0px;
  }
}
 .md-virtual-repeat-container .md-orient-vertical {
  margin-top: 8px;
  height: 400px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-radius: 4px;
}

.md-virtual-repeat-container {
  box-sizing: border-box;
  display: block;
  margin: 0px;
  overflow: hidden;
  padding: 0px;
  position: relative;
}
.code {
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
  padding: 4px;
  font-size: 90%;
}
.filter-set{
background-color: #03a5fc;
border: 1px solid #03a5fc;
border-radius: 5px;
padding: 9px 6px 6px 6px;
margin-left: 8px;
cursor: pointer;
}

.search {
  font-size: 1.375rem;
  font-weight: 300;
  font-style: italic;
  line-height: 26px;
  width: 18rem;
  height: 23px;
  @include myTheme.theme-aware('background', 'dropdown-s2-color-bg');
  outline: none;
}
.search-grn{
  @extend .search;
  margin-top: 1rem;
  padding-bottom: 0.4rem;
}
.search-layout{
  @extend .search;
  @include myTheme.theme-aware('background', 'dropdown-s3-color-bg');
  color:#ffff
}
.search-portable {
  @extend .search;
  width: unset;
  font-size: unset;
}
.search_dashboard_list {
  font-size: 1.175rem;
  font-weight: 300;
  font-style: italic;
  line-height: 26px;
  width: 12.5rem;
  height: 30px;
  color:#fff;
  @include myTheme.theme-aware('background', 'sideSubMenu-bg');
  outline: none;
}

.search::placeholder {
  @include myTheme.theme-aware('color', 'dropdown-s2-color-text');
  font-style: italic;
  font-size: 1rem;
  @extend .fontLight;
}

.search-crew{
  @extend .search;
  width: 11rem;
  max-width: 15rem;


}


.searchReconFilter{
  font-style: normal !important;
  font-family: inherit;
  font-size: 0.875rem;
  font-weight: 400;
}

.searchReconFilter::placeholder{
  font-style: normal !important;
  font-family: inherit;
  font-size: 0.875rem;
  font-weight: 400;
}

.galley_add{
  border:0.7px solid #dfdfdf;
border-radius: 4px;
 padding:3.5rem;
}
.input {
  width: 100%;
  border-radius: 6px;
  height: 33px
}

.clientLogo {
  width: 20%
}

.ag-root-wrapper {
  border-radius: 8px;
}

hr {
  height: 0rem;
  border: 0.031rem solid rgba(176, 60, 60, 0.5);
}
.scroll-container{
  width: 96%;
  overflow-x: scroll;
  display: flex;
  border-radius: 4px;
  margin:0;
  padding: 0;
  justify-content:flex-start;
}
.scroll-container-perf{
  @extend .scroll-container;
  width: 100%;

}

.sm-scrollbar {
  overflow: auto;

  /* width */
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 4px;
  }
  /* Show scroll bar on Firefox browser */
  &::-moz-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: rgba(105, 103, 103, 0.75)
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, .5);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, .5);
  }
}

/* buttons */
.cta-button {
  background: #7DA4B1;
  border: 0.070353125rem solid #7DA4B1;
  box-shadow: 0rem 0.070353125rem 0.14070625rem rgba(16, 24, 40, 0.05);
  border-radius: 0.625rem;
  width: 11.9375rem;
  height: 3.125rem;
  color: #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.70353125rem 1.26635625rem;
}

.add-condition {

  font-weight: 400;
  text-align: center;
  height: 2.2rem;
  width: 8.1rem;
  max-height: 3.4rem;
  max-width: 9.2rem;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #fff;
  background-color: #70CEEA;
  border-color: #70CEEA;
}

.button2 {
  background: #203354;
  box-shadow: 0rem 0.125rem 0.125rem rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 0.125rem;
  width: 27.0625rem;
  height: 3.3125rem;
  color: #FFFFFF;
}

.additional-button {
  background-color: #70CEEA;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  width: 25px;
  height: 25px;
  margin-left: 10px;
  margin-top: 19px;
  padding: 0;
  display: flex;
  justify-content: center;
  border-radius: 999px;
}
.remove-subcategory-items {
  @extend .additional-button;
  background-color: #3b7f96;
}
.padding3{
  padding-top: 1.8rem;
  padding-bottom: 1.8rem;
}
.padding4{
  padding:10px
}
.padding3-1{
  padding: 6px;
}
.button5 {
  border-radius: 80%;
}

#category-name-add,
#map1111 {
  vertical-align: middle;
}
.sector-label{
  background-color: #d9edf8;
  color: #000000;
  margin-right: 5px;
  border-radius: 4px;
  padding: 5px;
}
.uplift-user{
  @extend .sector-label;
  padding: 8px;
  overflow-wrap: break-word;
}

.sector-wrapper {
  display: flex;
  align-items: center;
}

.sector-button-operations {
  @include colors.primaryColor2;
  min-width: 1.5rem;
  height: 1.25rem;
  cursor: pointer;
  padding: 5px;
  border-radius: 0.125rem;
  color: #071B28;
  margin-left: 20px;
}
.sector-button-container{
    display: flex;
    align-items: center;
}
.sector-container {
  white-space: nowrap;
  display: inline-block;
  height: 100%;
}

.sector-container::-webkit-scrollbar {
  width: 5px;
  height: 0px;
}

.secondary-button {
  background: #000000;
  box-sizing: border-box;
  width: 27.0625rem;
  height: 3.3125rem;
  border: 0.0625rem solid #FFFFFF;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.14)) drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.12)) drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2));
  border-radius: 0.125rem;
}

.react-datepicker__input-container input::placeholder {
  color: #fff; /* Desired placeholder color */
}
.icon-button {
  background: #091b27;
  width: 7.375rem;
  height: 3.125rem;
  border-radius: 0.125rem;
  border: 0.0625rem solid #FFFFFF;
  @extend .body-text-regular;
  @extend .fontRegular;
  @extend .white;
}

.loading-img {
  width: 1.1rem;
  height: 1.1rem;
  margin: 0.4rem 0.3rem 0 0.3rem;
}
.white-button-text-small {
  border-radius: 0.25rem;
  border: 0.0625rem solid;
  @extend .small-button-text-regular;
  @include myTheme.theme-aware('border-color', 'dropdown-s1-color-text');
  @include myTheme.theme-aware('color', 'dropdown-s1-color-text');
  @include myTheme.theme-aware('background', 'dropdown-s1-color-bg');
}

.white-button-text-medium {
  border-radius: 0.25rem;
  border: 0.0625rem solid;
  @extend .dropdownStyle1-TextMedium;
  @include myTheme.theme-aware('border-color', 'dropdown-s1-color-text');
  @include myTheme.theme-aware('color', 'dropdown-s1-color-text');
  @include myTheme.theme-aware('background-color', 'dropdown-s1-color-bg');
}

.icon-button-fill {
  width: 15.8125rem;
  height: 3.25rem;
  background: #091b27;
  border-radius: 6px;
}

.trash-button {
  border-radius: 50px ;
  border: 1px solid white;
  color: white;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
}

.page-button {
  @extend .icon-button;
  @include myTheme.theme-aware('background', 'dropdown-s2-color-bg');
  margin-left: .3rem;
  margin-bottom: 1rem;
  width: 4.1rem;
  height: 2.225rem;
  align-items: center;

  select {
    @include myTheme.theme-aware('background', 'dropdown-s4-color-bg');
  }
}
.status-button {
  @extend .icon-button;
  @include myTheme.theme-aware('background', 'dropdown-s4-color-bg');
  // margin-left: .3rem;
  // margin-bottom: 1rem;
  min-width: 8.5rem;
  margin-top:1.8rem;
  height: 2.35rem;
  align-items: center;
  font-size: small;
  border-radius: 4px;
  overflow: hidden;
  display: flex;

  select {
    @include myTheme.theme-aware('background', 'dropdown-s4-color-bg');

    width: 100%;
      border: 0px;
      outline: 0px;
     padding: 8px;
      // margin-right: 10px;
      // min-height: 48px;
      // padding-right: 40px;

  }
}

//  DASHBOARD  //

.dashboard-header {
  margin-bottom: 2rem;
}

.dashboard-header-increment {
  color: #09C0D7;
  font-size: small;
}

.dashboard-header-decrement {
  color: #F06548;
  font-size: small;
}

.dashboard-header-button {
  margin-right: calc(1rem + 1vw);
}
.sales_mode{
  display: flex;
  margin-top: 1.3rem;
  justify-content: space-between;
}

.score_display{
  display: flex;
  width: 33.3%;
  margin-bottom: 3rem;
}
.column {
  height: 20px;
  margin: 5px;
  text-align: center;
}

.progress-div {
  background-color:rgba(255, 255, 255, 0.2);
  border-radius: 20px;
}

.progress {
  background-color: #63ABFD;
  width: 207px;
  height: 8px;
  left: 0px;
  top: 44px;
  border-radius: 20px;
  transition: 1s ease;
  transition-delay: 0.5s;
}

.loader-1 {
  width: 48px;
  height: 48px;
  border: 5px solid rgba(1, 188, 245, 0.16);
  border-bottom-color: #3de2ff;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.dashboard-header-title {
  float: left;
  padding-top: 0.31rem;
}
.status-instance{
    display: inline-block;
    padding: 6px 6px;
    color: #fff;
    border: none;
    border-radius: 4px;
    text-align: center;
    text-decoration: none;
    margin-left: 10px;
    font-size: 12px;
    font-weight: bold;

}
.status-consolidate-orders{
@extend .status-consolidate;
cursor: pointer;
}
.status-consolidate-orders:hover{
  opacity: 0.8;
}

.status-dynamic{
  @extend .status-instance;
  cursor: pointer;
  margin:5px 0px;
  background-color:#29d329;
}
.status-dynamic:hover{
background-color:#2cca2c;

}
.strikethrough {
  text-decoration: line-through;
}
.dynamic-act{
width:17rem;
white-space:nowrap;
background-color:#d9534f;
}

.dynamic-upt{
width:17rem;
white-space:nowrap;
background-color: #001b2b;
}
.dynamic-upt:hover{
background-color: #012b4b;
}
.dynamic-act:hover{
background-color:#ed403a;

}
.manage-stock-details{
  cursor: pointer;

}
.manage-stock-details:hover{
  font-size: 0.9rem;
  font-weight: 600;
}
.dynamic-sl{
  width:17rem;
  white-space:nowrap;
  background-color: #006385;
}
.dynamic-sl:hover{
  background-color:#047296;
}

.status-consolidate{
  @extend .status-instance;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-button-report {
  background: #0D2636;
  color: #EBEBEB;
  width: 5.5rem;
  height: 1.225rem;
  align-items: center;
}

.dashboard-border {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 11px;
  font-weight: 400;
  border-color: #FFFFFF;
  border-width: 0.01px;
  border-radius: 2.5px;
  margin-bottom: 1rem;
}

.dashboard-border-listview {
  @extend .dashboard-border;
  margin-bottom: 0.5rem;
}

.component-header-space {
  width: 200px;
  font-size: 0.83rem;
  font-weight: 600;
}

.component-header-space-list {
  width: 280px;
  font-size: 1rem;
  font-weight: 600;
}

canvas {
  width: 95% !important;
  height: 95% !important;
}
.chart-container-modal {
  width: 100vw !important;
  height: 100vh !important;
  overflow: hidden !important;
  .chart-container{
    width: 100% !important;
  }
}
.chart-container{
  position: relative;
  .expander{
    position: absolute;
    bottom: 16px;
    right: 16px;
    color: #FFF;
  }
}
.chart-label-pie {
  width: 40%;
  height: 70px;
}

.chart-label-bar-chart {
  flex: 1 0 37%;
  height: auto;

  ul {
    padding-inline-start: 25px;
    display: flex;
    justify-content: center;
  }
}

.chart-label-seperator {
  border-left: 1.39384px solid rgba(255, 255, 255, 0.12);
  padding-inline-start: 35px;
}

.chart-heading-pie {
  font-style: normal;
  font-weight: 400;
  font-size: .8rem;
  line-height: 17px;
  color: #EBEBEB;
}
.enlarge-chart{
  margin: auto;
  display: block;
  height: 11px;
  width: 400px;
  font-size: 30px;
}

.chart-value-pie {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #FFFFFF;
}

.icon-enlarge{
  margin-top: 1.44rem;
  cursor: pointer;
  float: right;
  margin-left: auto;
  margin-right: auto;
}

.icon-enlarge-list{
  cursor: pointer;
  float: right;
  margin: 0 20px 8px auto;
}
.icon-estm{
  filter: brightness(0.6) invert(1);
}

ul {
  list-style: none !important;
}

li {
  position: relative !important;
}

.dashboard-bullet::before {
  content: "\2022";
  font-weight: bolder;
  top: -5px;
  position: absolute;
  font-size: 1.2rem;
  left: -20px;
}

.dashboard-bullet-bar::before {
  content: "\25AC";
  font-weight: bolder;
  top: -7px;
  position: absolute;
  font-size: 1.2rem;
  left: -20px;
}

.trolley-bullet::before {
  content: "\25AC";
  font-weight: bolder;
  top: -7px;
  position: absolute;
  font-size: 1.2rem;
  left: -20px;
  top: 4px;
}

.trolley-bullet {
  padding: 10px;
  cursor: pointer;
}

.tb-tabs {
  color: #70CEEA;
  font-weight: 700;
}

select#page-size {
  border: 0px;
  outline: 0px;
  // margin-right: 10px;
  // min-height: 48px;
  // padding-right: 40px;
}
select#item-type {
  border: 0px;
  outline: 0px;
  margin-right: 10px;
  min-height: 38.4px;
  padding-right: 40px;
}

.button-align {
  width: 100%;
  padding: 1rem 2.5rem;
  text-align: center;
  cursor: pointer;
}

.button-size {
  width: 25.0625rem;
  height: 3.3125rem;
}

.bar-chart-legend {
  content: "\1F030";
  font-weight: bolder;
  top: -5px;
  position: absolute;
  font-size: 1.2rem;
  left: -20px;
}

.unset-width {
  width: unset !important;
  max-width: unset !important;
  min-width: unset !important;
}

.link {
  font-family: 'Inter', sans-serif;
  font-size: .86rem;
  margin-bottom: 1rem;
  cursor: pointer;
  @extend .fontMedium;
}

.upload {
  @extend .link;

  cursor: pointer;
  .loading {
    width: 2em;
    height: 2em;
  }
  &.disabled {
    cursor:not-allowed;
  }

}

.column1{
  width: 40%

}

.column2{
  width: 60%
}

.download {
  @extend .link;
  margin-left: 1rem;
}
.instance-member{
  font-size: 15px;
  font-weight: bold;
  color: #072a47;
}
.instance-member-name{
@extend .instance-member;
  color: #691313;
  background-color: #f3b2b2;
  border-radius: 4px;
  padding: 3px;
  margin: 0px 5px 0px 5px;
}
.icon-size {
  cursor: pointer;
  width: 1rem;
  height: 1rem;
  margin:0.3rem 0.3rem 0.4rem 0rem;
}
.icon-grn {
  cursor: pointer;
  width: 1rem;
  height: 1rem;
  margin: 1rem 0.3rem 0rem 0rem;
}
.icon-charts{
  cursor: pointer;
  width: 0.85rem;
  height: 0.85rem;
  margin-right: .5rem;
  margin-bottom: 0.5rem;
}
.icon-size-role {
  @extend .icon-size;
  width: 1.7rem;
  height: 1.7rem;

}
.icon-size-sales_delta {
  min-width: 1rem;
  max-width: 1.5rem;
  height: 0.85rem;
}
.icon-divider {
  width: 1rem;
  height: 2rem;
  margin-right: .3rem;
  margin-bottom: .2rem;
}
.icon-divider-charts{
  @extend .icon-divider;
  height: 1.2rem;
}
.flex_container_galley{
  width: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.round-border {
  border: 1px solid #071B28;
  border-radius: 40%;
  font-size: 79%;
  width: 50%;
  // margin-right: 10px;
  padding: 7px;
  font-weight: 800;
  background-color: #1d3e4e;

}
.round-border-count{
@extend .round-border;
color:  #70CEEA;
background-color: #071B28;
}
.done-button {
  @include colors.primaryColor2;
  min-width: 10rem;
  height: 3.125rem;
  border-radius: 0.125rem;
  margin-bottom: 1rem;
  color: #071B28;
  cursor: pointer;

  &.cancel-button {
    @include colors.nuetralColor2;
  }
}
.save-button-galley{
  @extend .done-button;
  min-width: 12.5rem;
}
.error-det-isolates-heavy{
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
  border-radius: 0.5rem;
  padding: 10px;
  margin-bottom: 0.2rem;

}

.error-det-isolates-mild{
  @extend .error-det-isolates-heavy;
  color: #595422;
  background-color: #e7db76;
  border-color: #e7db76;
}

.done-button-selection {
  @extend .done-button;
  cursor: not-allowed
}

.reset-button {
  background-color: #060b26;
  min-width: 168px;
  height: 38px;
  border: 2px solid #70CEEA;
  border-radius: 0.425rem;
  color: #ffffff;
}
.reset-button-disabled{
  @extend .reset-button;
  background-color: rgba(6, 11, 38, 0.1);
  color: rgba(255, 255, 255, 0.4);
  border: 2px solid rgba(112, 206, 234, 0.4);
}

.apply-button {
  background-color: #70CEEA;
  min-width: 168px;
  height: 38px;
  border-radius: 0.425rem;
  color: #071B28;
  font-weight: 500;
  cursor: pointer;
}
.edit-icon{
background-color: #03a5fc;
border-radius: 2px;
min-width: 0.8rem;
width:1.1rem;
height: 0.9rem;
}
.download-button {
  @include colors.primaryColor2;
  min-width: 10rem;
  height: 3.125rem;
  border-radius: 0.125rem;
  color: #071B28;
  cursor: pointer;

  &.cancel-button {
    @include colors.nuetralColor2;
  }
}
.download-button-g {
  @extend .download-button;
  @include colors.primaryColor6;
  border-radius: 0.21rem;
}

.sector-button {
  @include colors.primaryColor2;
  right: 0;
  min-width: 1.2rem;
  height: 2.25rem;
  border-radius: 0.125rem;
  color: #071B28;
}

.submit-button {
  @include colors.primaryColor2;
  min-width: 10rem;
  height: 3.125rem;
  border-radius: 0.125rem;
  color: #071B28;
  cursor: pointer;

  &.cancel-button {
    @include colors.nuetralColor2;
  }

  &.failedCls {
    @include colors.nuetralColor2;
  }
}

.uploadImage-button {
  @include myTheme.theme-aware('background-color', 'formImageUploadButtonColor-bg');
  min-width: 8.89rem;
  height: 3.125rem;
  margin-right: 3rem;
  margin-bottom: 1rem;
  border: 1px solid white;
  border-radius: 0.125rem;
  color: #ffffff;
}

.dashboard-button {
  min-width: 6rem;
  height: 2.4rem;
  border: 1px solid white;
  border-radius: 0.325rem;
  color: #ffffff;
  margin-right: 1.2rem;
  background-color: #001B2B;
  padding: 4px 14px 4px 14px;
}
.dashboard-button:hover{
  background-color:  rgba(150, 150, 150, 0.26);
  -moz-transition: all .6s;
  -webkit-transition: all .6s;
  transition: all .6s;
}
.dashboard-button-main {
  @extend .dashboard-button;
  margin-right: 1%;
  height: 2rem;
}
.customize-button {
  min-width: 6rem;
  height: 2rem;
  border: 1px solid  #70CEEA;
  border-radius: 0.325rem;
  margin-right: 1%;
  color: #0E0E0E;
  background-color: #70CEEA;
  padding: 4px 14px 4px 14px;
}

.customize-button:hover {
  background-color: #16CAFF;
  -moz-transition: all .6s;
  -webkit-transition: all .6s;
  transition: all .6s;
}
.button-content-roles {
  display: flex;
  align-items: center;
  margin-top: 0.1rem;
}


.customize-button-role {
@extend .customize-button;
  border: 1px solid  #FFFFFF;
  border-radius: 0.325rem;
  color: #0E0E0E;
  background-color: #FFFFFF;
  padding: 4px 14px 11px 14px;
  margin-bottom: 1.2rem;
}

.customize-button-role:hover{
  background-color: #d9534f;
  border:1px solid #d9534f;
  -moz-transition: all .6s;
  -webkit-transition: all .6s;
  transition: all .6s;
}


.download__banner_ {
  color: #FFFFFF;
  white-space: nowrap;
}

.download__banner_active {
  cursor: not-allowed;
}

.input_display {
  display: none;
}

.white-button {
  background: #FFFFFF;
  border: 0.075rem solid #D0D5DD;
}

.cancel-button, .close-button {
  @include colors.nuetralColor2;
}

.cancel-button, .close-button {
  min-width: 10rem;
  height: 3.125rem;
  margin-right: 3rem;
  border-radius: 0.125rem;
  color: #071B28;
  background: #FFFFFF;
  cursor: pointer;
}

.continue-button {
  min-width: 10rem;
  height: 3.125rem;
  margin-right: 3rem;
  border-radius: 0.125rem;
  color: #FFFFFF;
  background:#F06548;
  cursor: pointer;
}
.split-button-left
{
display: flex;
justify-content:flex-start;
width: 80%;
align-items: center;
white-space: nowrap;
padding: 0;
border: none;
background-color: transparent;
cursor: pointer;
}

.split-button-right
{
display: flex;
width: 20%;
justify-content: flex-end;
align-items: center;
white-space: nowrap;
padding: 0;
border: none;
background-color: transparent;
cursor: pointer;
}

.add-emp-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  padding: calc(1vw - 8px) calc(1vw + 2px);
  @extend .fontMedium;
  @include myTheme.theme-aware('background-color', 'formAddButtonColor-bg');
  @include myTheme.theme-aware('color', 'blue-button-color-text');
  min-width: 8.75rem;
  max-width: 16.75rem;
  white-space: nowrap;
  height: 2.4rem;
  margin:0rem 1rem 0rem 0rem;
  border-radius: 0.25rem;
}

.isolate-cont-commit {
   @extend .add-emp-button;
  @include myTheme.theme-aware('background-color', 'successText-bg');
  @include myTheme.theme-aware('color', 'primaryColor2-color');
}
.isolate-cont-control {
  @extend .add-emp-button;
 @include myTheme.theme-aware('background-color', 'formInstanceControl-bg');
 @include myTheme.theme-aware('color', 'primaryColor2-color');
}

.isolate-cont-control-opaque {
  @extend .isolate-cont-control;
  opacity: 0.6;
}

.isolate-cont-commit-opaque {
  @extend .isolate-cont-commit;
 opacity: 0.6;
}

.lock-instance-button{
  @extend .add-emp-button;
  @include myTheme.theme-aware('background-color', 'formRemoveButtonColor-bg');
  @include myTheme.theme-aware('color', 'primaryColor2-color');
}
.conflict-resolve-button{
  @extend .lock-instance-button;
  @include myTheme.theme-aware('background-color', 'formFixButtonColor-bg');
  @include myTheme.theme-aware('color', 'formFixButtonColor-bg');
  min-width: 8rem;
  max-width: 10.5rem;
  white-space: nowrap;
  height: 1.65rem;
  margin:0.2rem;
  border-radius: 0.25rem;
}
.save-dfp-edits{
  @extend .add-emp-button;
  width: 6.4rem;
  @include myTheme.theme-aware('background-color', 'primaryColor2-bg');
  @include myTheme.theme-aware('color', 'dropdown-s1-color-text');
  min-width: 6.3rem;
}
.dialog {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Box shadow for a slight depth effect */
}



.add-hsnmap-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter', sans-serif;
  font-size: 0.9rem;
  padding: calc(1vw - 8px) calc(0.5vw + 2px);
  @extend .fontMedium;
  @include myTheme.theme-aware('background-color', 'formAddButtonColor-bg');
  @include myTheme.theme-aware('color', 'blue-button-color-text');
  min-width: 8.75rem;
  max-width: 16.75rem;
  height: 2.4rem;
  border-radius: 0.25rem;
}

.add-item-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  padding: calc(1vw - 8px) calc(1vw + 2px);
  @extend .fontMedium;
  @include myTheme.theme-aware('background-color', 'formAddButtonColor-bg');
  @include myTheme.theme-aware('color', 'blue-button-color-text');
  min-width: 8.75rem;
  max-width: 14.75rem;
  white-space: nowrap;
  height: 2.4rem;
  border-radius: 0.25rem;

  .add-icon {
    margin-right: 8px;
    font-size: inherit;
    height: 2.4rem;

  }
}
.add-item-button-all{
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  padding: calc(1vw - 8px) calc(1vw + 2px);
  @extend .fontMedium;
  min-width: 8.75rem;
  max-width: 14.75rem;
  white-space: nowrap;
  height: 2.4rem;
  border-radius: 0.25rem;
  @include myTheme.theme-aware('background-color', 'uiColor3-bg');
  @include myTheme.theme-aware('color', 'blue-button-color-text');
}
.mod-banktranser-button {
  @extend .add-item-button;
  display: flex;
  align-items: center;
  min-width: 3.75rem;
  max-width: 8.75rem;
  height: 2.15rem;

}

.add-role-button {
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  padding: calc(1vw - 8px) calc(1vw + 2px);
  @extend .fontMedium;
  @include myTheme.theme-aware('background-color', 'formAddButtonColor-bg');
  @include myTheme.theme-aware('color', 'blue-button-color-text');
  min-width: 5.25rem;
  max-width: 16.75rem;
  height: 2.57rem;
  width: auto;
  margin-top: 3.35rem;
  margin-right: 26rem;
  border-radius: 0.25rem;
}
.m-2{
  margin:0.5rem 0.5rem  0.5rem 0rem !important;
}
.add-role-button--disabled {
  @extend .add-role-button;
  cursor: not-allowed;
}

.button_group {
  float: right;
  margin-top: 1rem;
  margin-right: .2rem;
  cursor: pointer;
}

.remove-button {
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  padding: calc(1vw - 8px) calc(1vw + 2px);
  @extend .fontMedium;
  @include myTheme.theme-aware('background-color', 'formRemoveButtonColor-bg');
  @include myTheme.theme-aware('color', 'red-button-color-text');
  min-width: 8.75rem;
  max-width: 16.75rem;
  height: 2.4rem;
  margin:0rem 1rem 0rem 0rem;
  border-radius: 0.25rem;
}

.remove-button-hidden {
  @extend .remove-button;
  cursor: not-allowed
}

.modal-remove-button {
  @extend .remove-button;
  width: 3.75rem;
  height: 2.6rem;
}
.remove-button-roles {
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  padding: calc(1vw - 25px) calc(.3vw + .2px);
  @extend .fontMedium;
  background-color: #1d3e4e;
  min-width: 3.2rem;
  max-width: 5.75rem;
  min-height: 2.3rem;
  max-height: 3rem;
  border-radius: 0.25rem;
}

.remove-button-galley{
  @extend .remove-button-roles;
  @include myTheme.theme-aware('background-color', 'galleyRemoveButtonColor-bg');
  min-height: 2.8rem;
}

.error-msg {
  color: #F06548;
}
.crew-container {
  display: flex;
  flex-wrap: wrap;
}

.employee-crew {
  margin-right: 4px;
  border: none;
  background-color: #3e687d;
  color: #ededed;
  border-radius: 5px;
  padding: 8px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(25% - 8px);
  box-sizing: border-box;
}
.select-item {
  @extend .white-button-text-medium;
  font-family: 'Inter', sans-serif;
  padding: calc(0.65vw - 8px) calc(1vw + 2px);
  min-width: 13.75rem;
  border:0.6px solid #ffff;
  max-width: 18.75rem;
  width: 11.5rem;
  height: 2.4rem;
  @include myTheme.theme-aware('background-color', 'dropdown-s4-color-bg');
  background-image: url("../arrow-down.svg");
  background-repeat: no-repeat;
  background-position-x: calc(100% - calc(0.3vw + 5px));
  background-position-y: 50%;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  background-size: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &.warehouse-list {
    min-width: 16rem;
    max-width: 18rem;
  }
}

.select-item-ak{
  @extend .select-item;
  min-width: 12.65rem;
}
.select-items-tr-ak{
  @extend .select-item;
  min-width: 13.75rem;
}

.select-item-rack{
  @extend .white-button-text-medium;
  font-family: 'Inter', sans-serif;
    min-width: 2.75rem;
    max-width: 5.85rem;
    width: 5.7rem;
    height: 1.65rem;
    padding-left: 3px;
}

.select-item-galley-des{
@extend .select-item;
min-width: 25.15rem;
max-width: 30.75rem;
width: 25.5rem;
}
.aircraft-actions{
  width: 20px;
  height:20px;
  cursor: pointer;
  margin-right: 8px;
}
.action-Component-items{
  width: 20px;
  height:20px;
  cursor: pointer;
}
.LOI-actions{
  @extend .aircraft-actions;
  width:auto;
  height: 30px;
}
.loi-attachment-downld{
cursor: pointer;
color:  #70CEEA;

}
.loi-attachment-downld:hover{
color:  #45b4d6;
}
.widget-button {
  @extend .select-item;
  background-image: none;
  background-color: #FFFFFF;
  color: #000000;
  margin-left: 0;
  height: 2.5rem;
  font-size: 16px;
  padding: 4px 14px 4px 14px;
}
.widget-button-main {
  @extend .widget-button;
  height: 2rem;
  margin-right: 1%;
}

.sector-div {
  .select-item {
    min-width: 1.75rem;
    width: 12rem !important;
    font-size: 0.8rem;
    border:0.6px solid #ffff
  }
  .select-item-cater {
    width: 13rem !important;
    border:0.6px solid #ffff
  }
}
.orderBy-div {
  .select-item {
    min-width: 1.75rem;
    width: 13rem !important;
    font-size: 0.8rem;
    border:0.6px solid #ffff
  }
}
.orderStatus-div {
  .select-item {
    min-width: 1.75rem;
    width: 11rem !important;
    font-size: 0.8rem;
    border:0.6px solid #ffff;
  }
}
.saleType-div {
  .select-item {
    min-width: 1.75rem;
    width: 13rem !important;
    font-size: 0.8rem;
    border:0.6px solid #ffff;
  }
}
.paymentType-div {
  .select-item {
    min-width: 1.75rem;
    width: 11rem !important;
    font-size: 0.8rem;
    border:0.6px solid #ffff
  }
}
.orderByDateInp {
  min-width: 5.5rem;
  width: 12rem !important;
}

.widget-button:hover{
  background-color: #F6F8F9;
}

.filter-select-button2 {
  font-family: 'Inter', sans-serif;
  padding: calc(1vw - 8px) calc(1vw + 2px);
  min-width: 13.75rem;
  max-width: 18.75rem;
  width: 11.5rem;
  height: 2.65rem;
  border-radius: 0.25rem;

  background-image: url("../arrow-down.svg");
  background-repeat: no-repeat;
  background-position-x: calc(100% - calc(1vw + 2px));
  background-position-y: 50%;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  background-size: 16px;

  &.warehouse-list {
    min-width: 16rem;
    max-width: 18rem;
  }
}


.widget-button, .customize-button, .filter-select {
  img {
    float: left;
   }
}

.sign-in {
  @include myTheme.theme-aware('background-color', 'blue-button-color-bg');
  @include myTheme.theme-aware('color', 'blue-button-color-text');
  border-radius: 0.125rem;
  @extend .button-align;
  @extend .button-size;
}

.blank-button {
  @extend .white-button;
  border-radius: 0.125rem;
  @extend .button-align;
  @extend .button-size;
}

//checkbox
.checkbox {
  width: 1.2em;
  height: 1em;
  margin-right: 5px;
  background: #FFFFFF;
  border: 0.0625rem solid rgba(64, 64, 64, 0.4);
  border-radius: 0.125rem;
}

// fields
.input-field {
  box-sizing: border-box;
  width: 25.0625rem;
  height: 3.3125rem;
  background: #FFFFFF;
  border: 0.075rem solid #D0D5DD;
  border-radius: 0.125rem;
  color:#667085;
}
.input-field-password-view {
  @extend .input-field;
  padding-right:50px;
  background: url('../../images/icons/plus.png') no-repeat right center;
  background-size: 20px 20px;
}

.input-field-email{
  @extend .input-field;
  color:rgb(140,140,140);
}

.sign-in-field {
  padding: 0.1rem 0.4rem 2rem 0.4rem;
  width: 27.0625rem;
}
.dropdown-style1 {
  top: 2%;
  right: 2%;
  position: absolute;
}
.galley_box_load{
  outline:"1px solid";
  padding:'3px';
  top: 5;
  z-index: 1;
  position:'sticky';
  background-color:'#bebebe'
}

.galley_box_load,
u {
  outline:"1px none";
  padding:'3px';
  top: 5;
  z-index: 1;
  position:'sticky';
  background-color:'#bebebe';
  margin-left:'1rem';
  white-space:'nowrap';

}
.dashboard-summary-container {
  position: relative;
  --card-top-margin: 10px;

  @include myTheme.theme-aware('background', 'color-background2');
  padding: 0 0rem 8rem calc(0rem + var(--card-top-margin));
  margin: 0;

  @extend .height100;
  overflow: auto;

  .scrollbar-track {
    @include myTheme.theme-aware('background', 'color-background2');
  }

  .row {
    margin-top: calc(0 * var(--bs-gutter-y));
    margin-right: calc(0 * var(--bs-gutter-x));
  }

  .card-container-sales-header {
    border-radius: 4px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
    border-top: 0.6rem solid;
    @include myTheme.theme-aware('border-color', 'cardBorderColor');
    @include myTheme.theme-aware('background', 'cardContentColor-bg');
    width: calc((100% - 6%) / 4);
    min-height: 100px;
    margin: 0 0.5rem 0.5rem 0;
    @extend .white;
    @extend .small-button-text-regular;
    padding: 10px 15px 8px 15px;
    display: grid;
  }
  .card-sales:hover{
    @include myTheme.theme-aware('background', 'sideSubMenu-bg');
    -moz-transition: all .6s;
    -webkit-transition: all .6s;
    transition: all .6s;
    // cursor: pointer;
  }




  .card-container3 {
    border-radius: 8px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
    @include myTheme.theme-aware('border-color', 'cardBorderColor');
    @include myTheme.theme-aware('background', 'cardContentColor-bg');
    width: calc((100% - 5%) / 3);
    min-height: 230px;
    margin: 0 .5rem 1.5rem 0;
    @extend .white;
    padding: 0px 15px 2px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .card-container4 {
    border-radius: 8px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
    @include myTheme.theme-aware('border-color', 'cardBorderColor');
    @include myTheme.theme-aware('background', 'cardContentColor-bg');
    width: calc((100% - 36%));
    min-height: 230px;
    margin: 0 .5rem 1.5rem 0;
    @extend .white;
    padding: 0px 25px 2px 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
  }


  .card-container-sales-crew {
    border-radius: 10px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
    border-top: 16px solid;
    @include myTheme.theme-aware('border-color', 'cardBorderColor');
    @include myTheme.theme-aware('background', 'cardContentColor-bg');
    width: calc((100% - 10%) / 4);
    min-height: 240px;
    @extend .white;
    padding: 10px 25px 15px 25px;
    display: grid;

    .card-content2 {
      display: grid;
      justify-content: flex-start;
      align-content: center;
      min-height: 200px;
      margin: 8px;

      .card-icon-block {
        padding: 0 0 20px 0;

        img {
          height: calc(0.9rem + 2vw);
          width: calc(0.9rem + 2vw);
        }
      }

      .card-heading {
        @extend .card-heading-text;
        @extend .fontRegular;
      }

      .card-value {
        @extend .card-value-text;
        @extend .fontRegular;
      }
    }

    .loader-container {
      width: 100%;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      background: rgba(0, 0, 0, 0.834);
      z-index: 1;
  }

  .spinner {
      width: 64px;
      height: 64px;
      border: 8px solid;
      border-color: #ffcc01 transparent #f4e805 transparent;
      border-radius: 50%;
      animation: spin-anim 1.2s linear infinite;
  }

  @keyframes spin-anim {
      0% {
          transform: rotate(0deg);
      }
      100% {
          transform: rotate(360deg);
      }
  }

    .card-footer2 {
      justify-content: flex-end;
      border-top: 1.5px solid #7DA4B1;
      padding: 20px 0 0 0;
      @extend .card-footer-text;
      @extend .fontRegular;
      align-self: center;

      .icon {
        height: calc(0.1rem + 1vw);
        width: calc(0.1rem + 1vw);
        margin-right: calc(1vw - 0.4rem);
      }
    }
  }
}

.empty-dataSales{
height: 225px;
width: 350px;
display:inline-block;
text-align:center;

}
.zoom-container{
  justify-content: center;
  margin-left: auto;
}

.sector-detail-crew {
  border-radius: 10px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
  border-top: 16px solid;
  @include myTheme.theme-aware('border-color', 'cardBorderColor');
  @include myTheme.theme-aware('background', 'cardContentColor-bg');
  margin: 0 2.1rem 2.2rem 0;
  @extend .white;
  padding: 10px 25px 15px 25px;
  display: grid;
}

.sector-detail-crew {
  @extend .sector-detail-crew;
  width: 118%;
}

.sector-detail-summary {
  @extend .sector-detail-crew;
  width: 50%;
}

.sector-detail-devices {
  width: 40%;
  height: 430px;
  margin: 0 2.1rem 2.2rem 0;
  padding: 10px 25px 15px 25px;
  display: grid;
}

.image-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 26%;
  width: 35%;
}

.no-wrap {
  white-space: nowrap;
}

.flight-details-container {
  --reducePx: 4px;

  @include myTheme.theme-aware('background', 'color-background2');
  @include myTheme.theme-aware('color', 'color-foreground');

  padding: 1.2rem 1.2rem 5rem 1.3rem;
  margin: 0;
  @extend .height100;
  overflow: auto;

  .scrollbar-track {
    @include myTheme.theme-aware('background', 'color-background2');
  }

  .client-icon {
    height: 51.27px;
    width: 100.27px;
  }

  .category-menus-container {
    word-break: break-all;

    .category-link {
      padding: 0 10px 0 10px;
      @include myTheme.theme-aware('color', 'color-foreground');
      @extend .fontMedium;
      text-decoration: none;
      border-right: 1px solid #70CEEA;
      white-space: nowrap;

      &:first-child {
        padding-left: 0px;
      }

      &:last-child {
        border-right: none; //1px solid #70CEEA;
      }

      &:focus,
      &:hover,
      &.selected {
        color: #FFFFFF !important;
      }

    }
  }
}

.op-aircraft-container {
  width: inherit;

  .sheet-container {
    height: calc(90vh - var(--top-header-height) - 30px);
    overflow: auto;

    .Spreadsheet--dark-mode {
      --background-color: black;
      --text-color: white;
      --readonly-text-color: rgba(255, 255, 255, 0.9);
      --header-background-color: rgba(255, 255, 255, 0.04);
      --border-color: hsl(2deg, 0%, 19%);
    }

    label {
      @extend .global-text-light;
    }

    .leftBlock {
      display: flex;
      justify-content: flex-start;
    }

    .rightBlock {
      display: flex;
      justify-content: flex-end;
    }

    .errorCellData {
      border-bottom-color: red;
    }
  }
  .errorCellGRNrev{
    @extend .errorCellData;
  }
  .errorCellGRNrev:hover .sheetErrDiv{
    @extend .errorCellData;
    @extend .sheetErrDiv;
    left:-100% !important;
  }
  .scrollBarUplift{
    height: calc(90vh - var(--top-header-height) - -2px);
  }

  .dev-util {
    width: 270px;
    margin-top: 12px;

    .item {
      margin-bottom: 8px;
    }

  }

}

.fitcontent {
  margin: 0 auto;
  width: 100%;
}

.sector-banner {
  background-color: #f0f0f0;
  padding: 20px;
  height: 3.5rem;
  margin-bottom: 20px;
}

.reports-table{
  width: 80%;
  left:10%;
  display:flex;
}

.sector-banner{
  background-color: #3e687d;
  color: rgb(255, 242, 242);
  height: 2rem;
  border-radius: 5px;
  padding:30px;
  width:100%
}

.sector-origin-m {
  @extend .sector-banner;
  background-color: #fcf8e3;
  color: #8a6d3b;
  height: 1.2rem;
  padding:18px;

}
.sector-number{
  display: flex;
  margin-right: 7px;
  background-color: #082b4e;
  align-items: center;
  font-size: 25px;
  color: #47d26c;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  min-width: 60px;
}
.sector-tag{
  color: #09C0D7;
  font-size: 15px;
  font-weight: bold;
}

.se-titlebar {
  // width: 100%;
  background-color: #70CEEA;
  color: black;
  margin-top: 0.5rem;
  padding: 8px 16px;
  font-size: 16px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.reqm::after {
  content: '*';
  margin-left: 4px;
}

.input-container {
  .req::after {
    content: '*';
    margin-left: 4px;
  }

  .info::after {
    content: ' \24D8';
    margin-left: 4px;
  }

  .info:hover::before {
    content: attr(data-tooltip);
  }

  .info::before,
  .info::after {
    --scale: 0;
    --arrow-size: 20px;
    --tooltip-color: green;

    position: absolute;
    top: -.25rem;
    left: 50%;
    transform: translateX(-50%) translateY(var(--translate-y, 0)) scale(var(--scale));
    transition: 150ms transform;
    transform-origin: bottom center;
  }

  input:disabled,
  select:disabled {
    cursor: not-allowed;
  }

  input[type="file"] {
    padding-top: 8px;
  }
}

.content-container {
  padding: 1.5%;
  @include myTheme.theme-aware('background', 'color-background2');
  @include myTheme.theme-aware('color', 'color-foreground');
  overflow: hidden;
 @extend .side-menu-container;
}

button {
  &:disabled {
    cursor: not-allowed;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom:1.6vh;
  left:1.5%;
  flex-direction: row;
  font-weight: 300;
  font-size: 11px;
}
.footer_collapsed{
  @extend .footer;
  left:0.3%;
  flex-direction: column;
}
.footer-login{
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom:1.6vh;
}
.footer-timezone{
  position: absolute;
  bottom: 2.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  font-weight: 300;
  font-size: 11px;

  .date{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
}

.ng-grid-right {
  .remove-button {
    width: auto;
    margin-left: 16px;

    &:disabled {
      cursor: not-allowed;
      background-color: #e58e8d !important;
    }
  }

  .page-button {
    margin-bottom: 0;
  }
}

.device-page {
  .device-details {
    width: 100%;
    margin-left: 20%;
    .entry {
      width: 100%;
      flex-wrap: nowrap;
    }

    .value {
      width: 100%;
    }

    .row {
      margin-top: 8px;
    }
  }
}

.cell-loading {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  object-fit: cover;
}

.page-loading {
  width: 64px;
  height: 64px;
  object-fit: cover;
}

.registered-mark{
  position: relative;
  top: 0;
  right: 0;
  left:2px;
  font-size: 8px;
}

.date-input {
  .input {
    height: 2.225rem;
    padding: 8px;
    width: 12.1rem;
    text-align: center;
  }
}

.gridStatusBlock {
  margin: 20% 0 20% 0;

  p {
    @include colors.secondaryColor;
    padding: 2rem 2rem;
  }
}

.dfp-draft {
  .gdt-form {
    width: 100%;

    .warehouse-select {
      flex: 2;

      .select-input {
        width: 100%;
        text-align: center;
      }
    }

    .date-input-label {
      .date-input {
        margin: 0 32px 0 0;
      }

      .input {
        width: 100%;
        min-height: 40px;
        text-align: left;
        background-color: transparent;
        border: 0.75px solid rgb(255, 255, 255);
      }
    }

    .btn-generate {
      flex: 1;
      padding: 12px 0 0 16px;
    }
  }

  .draft-grid {
    width: 70%;
    height: 100%;
  }

  .note-cont {
    width: 30%;
    padding: 48px 0 0 16px;
  }

  .note {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border: 1px solid #faebcc;
    border-radius: 4px;
    height: 50px;
  }

  .group-row {
    background-color: rgba(235, 235, 235, 0.36);
    color: #FFF;
  }

  .run-est {
    .date-input {
      width: 270px;
    }
  }
}

.custom-tooltip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 1rem 0.1rem 1rem;
  border-radius: 0.625rem;
}

.cash-reg {
  height: 100%;

  .filter-cash {
    margin-left: 15px;
  }

  .filter-cash .select-input {
    text-align: center;
  }

  .btn-add-deposit {
    margin-left: 16px;
  }

  .btn-view-report {
    border: 1px solid #BDBDBD;
    margin: 4px;
  }

  .cash-deposit-summary {
    .dialog__body_container {
      .ngo-grid {
        margin-bottom: 18px;
      }
    }
  }

  .sector-header {
    padding: 13px 15px;
    border-bottom: 1px solid #082b4e;
    background-color:#daecf8 ;
    border-radius: 4px;
    width: 100%;
    cursor: pointer;
  }

  .sector-details {
    border: 1px solid #082b4e;
    border-radius: 4px;
    background-color:#daecf8;
    -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    margin-bottom: 16px;

    .content {
      padding: 15px;
    }

    .key-value {
      color: #082b4e;
    }

    .gap {
      margin-bottom: 12px
    }

    .log-status {
      display: inline;
      padding: 0.2em 0.6em 0.3em;
      color: #fff;
      text-align: center;
      white-space: nowrap;
      border-radius: 0.25em;
      background-color: #777;

      &.success {
        background-color: #5cb85c;
      }
    }

    .btn-sync {
      color: #337ab7 !important;
      margin-left: 8px;
    }
    .btn-sync-zero{
      color: #be6b58 !important;
      margin-left: 8px;
    }
  }

  .tbl-domination {
    margin-top: 16px;
  }

  .actions {
    padding: 4px;

    .deposit-btn {
      margin-left: 16px;
    }
  }
}

.attachment-card {
  position: relative;
  width: 200px;
  border-radius: 4px;
  border: 1px solid #eee;
  background-color: #fafafa;
  padding: 8px;
  transition: all 0.2s ease-in-out;
  max-width: 100%;
  margin-right: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 8px;

  &:hover {
    .actions {
      visibility: visible;
    }
  }

  .icon-attach {
    color: #0D2842;
    font-size: 5em;
    text-align: center;
  }

  .file-name {
    margin-left: 16px;
    margin-top: 4px;
    color: #000000;
  }

  .actions {
    visibility: hidden;
    top: 0;
    width: 100%;
    position: absolute;

    .btn {
      border-color: #CCC;
    }

    .icon-cloud-download {
      color: #337ab7 !important;
    }

    .icon-bin {
      color: #a94442 !important;
    }
  }
}

.cash-reg-edit {
  .add-sc {
    margin-top: 8px;
  }

  .edit {
    width: 1rem;
    height: 1rem;
    margin: 0;
  }

}

.cash-sector-remove {
  .from-to {
    width: 100%;
    margin-bottom: 16px;

    .fl-no {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      min-width: 60px;
      color: #fff;
      background-color: #337ab7;
    }

    .fl-dt {
      padding: 8px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      background-color: #ededed !important;
    }

    .loc {
      color: #337ab7;
    }

    .icon-arrow-right2 {
      color: #d9534f !important;
      font-size: 1rem;
    }
  }
}

.code,
.dfp-code {
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
  margin: 0 6px;
  padding: 2px;
}

.cash-sector-add {
  .input-container {
    width: 100%;
  }

  .label {
    margin-bottom: 8px;
  }

  .sector-select {
    margin-top: 16px;
    min-width: 270px;
  }

  .labe-warn {
    color: #E70000;
    margin-bottom: 8px;

    &::before {
      content: "*";
      margin-left: 4px;
    }
  }

  .sector-card {
    width: 100%;
    position: relative;
    padding: 10px 15px;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid #ddd;
    color: #555;

    .date {
      margin-left: 6px;
    }

    .icon-checkmark {
      color: #3c763d;
      margin-left: 16px;
    }

    &:hover {
      color: #555;
      text-decoration: none;
      background-color: #f5f5f5;
    }
  }

  .sector-card.first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .sector-card:last-child {
    margin-bottom: 16px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}

.camelCaseText {
  text-transform: capitalize;
}

.search-box-mrgin{
  margin:2rem 0.6rem 0rem 0.6rem;
}
.cash-reg-bank-tr {
  .icon-edit {
    margin-right: 8px;
    font-size: 12px;
  }
}


/* Custom properties */
:root {
  --tooltip-text-color: white;
  --tooltip-background-color: black;
  --tooltip-margin: 30px;
  --tooltip-arrow-size: 6px;
}

.Tooltip-Wrapper {
  display: inline-block;
  position: relative;
}

.Tooltip-Tip {
  position: absolute;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px;
  color: var(--tooltip-text-color);
  background: var(--tooltip-background-color);
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
  white-space: nowrap;
}

.Tooltip-Tip::before {
  content: " ";
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: var(--tooltip-arrow-size);
  margin-left: calc(var(--tooltip-arrow-size) * -1);
}

.Tooltip-Tip.top {
  top: calc(var(--tooltip-margin) * -1);
}

.Tooltip-Tip.top::before {
  top: 100%;
  border-top-color: var(--tooltip-background-color);
}

.icon-aircraft-container {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tooltip-action {
  visibility: hidden;
  width: auto;
  background-color: #0D2636;
  font-family: 'Lato', sans-serif;
  font-size: small;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  left: 160%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s 0s, visibility 0s;
}

.ag-cell-value{
  overflow: visible !important;
}

.icon-aircraft-container:hover .tooltip-action {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s, visibility 0s 1.4s;
  left:35% !important;
  transform: translateX(50%) !important;
}

.info-label {
  font-size: 1.1rem;
  margin-top: 0.6rem;

}
.info-label-chart{
  font-size: 1.1rem;
}

.bg {
  display: block;
  height: 10px;
  margin:0 0 0.9rem 0.4rem;
  text-align: center
}

.bg-galley {
  display: flex;
  flex-direction: row;
  float: right;
  margin-right: 1.1rem;
}


.info {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0D2636;
  font-size: 10px;
  background-color: #ffff;
  border-radius: 50%;
  border: solid 1px #ffff;
  width: 17px;
  height: 19px;
  font-weight: bold;
  text-align: center;
}


.open-button {
  background-color: #555;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  position: fixed;
  bottom: 23px;
  right: 28px;
  width: 280px;
}

.chat-popup {
  display:block;
  position: fixed;
  bottom: 53;
  right: 3rem;
  border-radius: 5px;
  z-index: 9;
  background-color:#1d3e4e;
}

.header-flag{
margin-bottom: 1px solid black;
height: 1.2rem;
}

.form-container {
  width: 400px;
  padding: 10px;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
}

.status-bar-flag{
  background-color: #0C5061;
  border-color: #575757;
  padding: 15px;
  border-bottom: 1px solid black;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}


.nav-menu {
  background-color: #0D2636;
  color:white;
  width: 400px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: -100%;
  transition: 1050ms;
  z-index: 1;
}

.nav-menu.active {
  right: 0;
  transition: 1050ms;
}

.nav-menu-header {
  width: 400px;
  height: 62px;
  left: 0px;
  top: 0px;
  z-index: 1;
  position: -webkit-sticky;
  position: sticky;
  background-color: #0C5061;

}

.aircraft-styles{
  position:relative;
  cursor:pointer;
  width: 100%;
}

.flight-styles{
  border-radius: .25rem;
  height: 270px;
  padding-right: 30px;
}

.aircraft_modal{
  display: flex;
  float:left
}

.nav-menu-header-title {
  padding: 1.2rem;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #FFFFFF;
}

.nav-menu-subtitle {
  padding: 2rem 0 0 1rem;
  font-size: 18px;
  color: #FFFFFF;
}
.drag-reorder-widgets{
  pointer-events: none;
  display: flex;
  flex-direction: row;
  float: right;
}

.nav-menu-components-box {
  padding-inline-start: 18px !important;
}

.nav-menu-components {
  margin: 1rem 0rem 0 0;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4.60335px;
  display: flex;
  flex-direction: row;
  padding: 4px 5px 10px 0px;
  justify-content: center;
  align-items: center;
  width: 96%;

  .nav-menu-components-title {
    // font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    width: inherit;
  }

}
.nav-menu-components-galley{
  @extend .nav-menu-components;
  width: 100%;
}

.text-inactive{
  color: #9C9C9C;
}

.Images{
  border-radius: .25rem;
  width: 115%;
  height: auto;
  border-radius: 8px;
  cursor: pointer;
}

.ImagesTrolley {
  max-width: 150%;
  height: auto;
  width: auto\9; /* ie8 */
  border-radius: .25rem;
  height: auto;
  border-radius: 8px;
}

.ImagesTwo{
  border-radius: .25rem;
  width: 115%;
  height: 90%;
  border-radius: 8px;
  cursor: pointer;
}

.Images_galley {
min-width:100px;
width:500px;
height: auto;
min-height: 600px;
max-height: 1200px;
max-width: 900px;
}
.Images_galley_ak{
  @extend .Images_galley;
  width: auto;
  height: 70vh;
  overflow-y: auto;
}
.galley_box_color{
  background-color: #013ca2;
}
@media (max-width: 600px) {
  .Images_galley,
  .Images_galley_ak {
    max-width: 300px;
  }
}

@media (min-width: 601px) and (max-width: 1200px) {
  .Images_galley,
  .Images_galley_ak {
    max-width: 500px;
  }
}

.galley-list {
  display: none;
}
.list-items:hover + .galley-list {
  display: block;
}

.cart-display{
  border:0.3px solid grey;
  background-color:#1d3e4e;
  width: 100%;
  overflow-x: auto;
  border-radius: 5px;
}
.cart-display:hover{
  border:2px solid #0dcaf0;
  border-radius: 5px;
}
.cart-label{
  outline:1px solid;
  padding:3px;
  top: 5;
  z-index: 1;
  position:'sticky';
  background-color:'#bebebe'
}

.cart-title{

    outline: 1px solid;
    padding: 3px;
    background-color: #bebebe;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cart-type{
    outline: none;
    padding: 3px;
    background-color: #ffffff;
    border-radius: 3px;
    white-space: nowrap;
}
.box-items{
  line-height: 1.5rem;
  text-align: left;
  padding: 0.3rem;
  list-style-type:none;
  float: left;
  color: #ffffff;}
.list-items:hover{
  color: #0dcaf0;
}

.image-block{
  position:relative;
}
.galley_box{
  color:#000000;
  margin-top:0.3rem;
}

.horizontal-flex{
  display: flex;
  align-items: center;
}
.cart_display_galley{
  @extend .customize-button-role;
  justify-content: center;
  align-items: center;
  padding-top: 1.2%;
  background-color: #ffffff;
  border: 1px solid  #ffffff;
}

.cart_display_galley:hover{
  background-color: #ffffff;;
}

.save-button{
  @extend .done-button;
 margin-right: auto;
}

.cancel-button-aircraft {
  @extend .uploadImage-button;
  margin-left: auto;
  margin-right: 0;
  min-width: 10rem;
  height: 3.125rem;
  border-radius: 0.125rem;
  margin-bottom: 1rem;

}


.ImageArea {
  background: #F0EDEB 0% 0% no-repeat padding-box;
  border-radius: 5px;
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(50px);
  position: absolute;
  padding: 0px 4px;
  box-sizing: border-box;
  font-size: 12px;
  cursor: pointer;
  overflow: unset;
  line-height: 1;
}

.ImageArea:hover{
  border:2px solid #0dcaf0;
  border-radius: 5px;
}











*, ::before, ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.container_tab {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 500px;
  height: 300px;
  background: #f1f1f1;
  margin: 100px auto 0;
  word-break: break-all;
  border: 1px solid rgba(0, 0, 0, 0.274);
}

.bloc-tabs {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.tabs {
  margin-top: 10px;
  padding: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  width: auto;
  background: white;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.274);
  box-sizing: content-box;
  border:3px;
  border-radius: 2px;
  position: relative;
  outline: none;
}
.tabs:not(:last-child){
  border-right: 1px solid rgba(0, 0, 0, 0.274);
}

.active-tabs  {
  background: white;
  border-bottom: 1px solid transparent;
}
.active-tabs-save  {
  color:rgb(12, 194, 12) ;
  font-weight: 450;
  font-size: 110%;
  border-bottom: 1px solid transparent;
}

.selectcell-class{
background-color:#6c757d;
color: #000 !important;
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
   top: -4.8px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 1px);
  height: 8px;
  border-radius: 7px 4px 2px;
  background: #70CEEA;
}

button {
  border: none;

}
.content-tabs {
  flex-grow : 1;
}
.content_default {
  background: #071B28;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: none;
}


.active-content {
  display: block;
  background: #071B28;
  color: white;
}

.column-trolley {
  float: left;
  padding: 10px;
}

.row-trolley:after {
  content: "";
  display: table;
  clear: both;
}

.text-center {
  text-align: center;
  margin-left: 2.5rem;
}

.rbc-header {
  overflow: hidden;
  flex: 1 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  padding: 5px !important;
  background-color: #70CEEA;
  color:  #0a2434;
  vertical-align: middle;
  height: 1.8rem;
  font-size: 100% !important;
  min-height: 0;
}

.rbc-show-more {
  background-color:#70CEEA !important;
  color: #000 !important;
  padding: 3px !important;
}

.rbc-show-more:hover {
@extend .rbc-show-more;
opacity: 0.6;

}

.mealplan-empty{
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 80vh;

}
.rbc-off-range-bg {
  background-color: #0a2434 !important;
}
.rbc-today{
  background-color: #1d3e4e !important;

}
  .rbc-btn-group {
    display: inline-block;
    white-space: nowrap;
background-color: #1d3e4e;
    .nav-button{
      color: #ffff;
    }
}
.rbc-btn-group:hover{
  background-color: #001b2c;
}
.rbc-toolbar-label{
  font-size: 20px;
  font-weight: 600;
}
.card-container3{
  .card-content2{
    min-height: 230px !important;
  }
}
.mealRotationTable td{
  font-size: 12px;
  user-select: text;
}
.mealRotationTable thead th{
  font-weight: bold;
  font-size: 12px;
  padding: 0 !important;
  border: none !important;
}
.mealRotationTable thead th .headCell{
  border:1px solid #fff;
  padding: 8px;
  border-collapse: collapse;
}
.alert-danger{
  color: #9a0202;
  border-radius: 5px;
  padding: 10px;
}
.toastCustom{
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: #fff;
  font-size: 12px;
  border-radius: 5px;
  padding: 2px 10px;
}
.removeEmpIcon{
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin: 0px 1px 3px 0px;
}

#mapping-table .select-input{
  width: 400px !important;
}
#mapping-table .dialog__description__checkbox{
  display: flex;
  align-items: center;
}
#mapping-table .dialog__description__checkbox input{
  margin-top: 0;
}
// Expandable table css
.exp_table{
  width: 100%;
  border-collapse: collapse; /* Ensures borders don't overlap */
  border: 1px solid #444242 !important;
}
.expTblLoadingOverlay {
  background-color: transparent; /* Semi-transparent background */
  display: flex;
  height: 30vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10; /* Ensure it’s above other elements */
}
.exp_table__wrapper{
  padding: 0;
}
.exp_table__head{
  background-color: #70ceea;
  height: 2vh;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.exp_table thead.mainHead tr{
  background-color: #0d2636;
  border: none;
  border-bottom: 1px solid #5e5e5e;
}
.exp_table tbody tr:nth-child(even){
  background-color: #1e3544;
}
.exp_table tbody tr.baseRow{
  cursor: pointer;
}
.exp_table tbody tr.baseRow td{
  padding: 10px !important;
}
.exp_table td{
  border: none !important;
}
.exp_table th{
  white-space: nowrap;
  border: none !important;
}
.rowDetail {
  height: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out, padding 0.3s ease-in-out;
}
.rowDetail.show {
  height: 500px;
  margin: 10px;
}
.exp_table tbody tr.baseRow.active{
  background-color: #0a2433;
}
.exp_table tbody tr.childRow.show td{
  background-color: #0a2433;
  user-select: text;
}
.arrowAup{
  position: absolute;
  right: 10px;
  top: 15px;
  transition: all 0.5s;
}
.arrowAup.open{
  transform: rotate(180deg);
}
.IrTopCardsWrapper{
  display: flex;
  justify-content: space-between;
}
.IrTopCard{
  width: 49%;
  padding: 0;
  margin-bottom: 1rem;
}
.IrTopCardInner{
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 0.5rem;
}
.IrTopCardInner .canvasWrapper{
  display: flex;
}
.canvasWrapper__inner{
  width: 50%;
  margin-bottom: 1rem;
}
.canvasTitle{
  display: flex;
  justify-content: center;
}
.legendWrapper{
  display: flex;
  gap:1rem;
  margin-top: 1.5rem;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  background-color: #3d687d;
  color: white;
  border-radius: 2px;
  padding: 5px;
  cursor: pointer;
}

.dropdown-content {
  position: absolute;
  right: 0;
  background-color: #001b2b;
  min-width: 12rem;
  border-radius: 2px;
  margin: 2px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border: 1px solid #001b2b;
}

.dropdown-content div {
  color: rgb(255, 255, 255);
  border-radius: 2px;
  padding: 10px;
  text-decoration: none;
  display: block;
}

.dropdown-content div:hover {
  background-color: #4c5c81;
  cursor: pointer;
}

.dropdown:hover .dropdown-content {
  display: block;
}


.cardViewToggler{
  // border: 1px solid #ffffff98;
  overflow: hidden;
  height: auto;
  transition: all 0.5s;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: transparent
}
.meter {
  box-sizing: content-box;
  height: 20px;
  position: relative;
  margin: 10px 0px;
  border-radius: 10px;
  background-color: #1d3e4e;
  padding: 5px;
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
}
.meter > span {
  display: block;
  height: 100%;
  border-radius: 8px;
  background-color: rgb(43, 194, 83);
  background-image: linear-gradient(
    center bottom,
    rgb(43, 194, 83) 37%,
    rgb(84, 240, 84) 69%
  );
  position: relative;
  overflow: hidden;
}
.meter > span:after,
.animate > span > span {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  z-index: 1;
  background-size: 50px 50px;
  animation: move 2s linear infinite;
  border-radius: 8px;
  overflow: hidden;
}

.animate > span:after {
  display: none;
}

@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}
.red > span {
  background-image: linear-gradient(#f0a3a3, #f42323);
}

.nostripes > span > span,
.nostripes > span::after {
  background-image: none;
}
.errorCellData{
  position: relative;
  z-index: 1;
  overflow: unset !important;
  word-break: break-all;
}
.errorCellData span{
  word-break: break-all;
}
.sheetErrDiv{
  position: absolute;
  top:0;
  left:0;
  opacity: 0;
  // transition:all 0.1s ease;
  z-index: 2;
  background-color: #fff;
  border-top: 5px solid red;
  padding: 1rem;
  width: max-content;
}
.errorCellData:hover .sheetErrDiv{
  color: red;
  opacity: 1;
  font-size: 14px;
  left:100%;
}

.bhNegetive{
  color: red;
  border-bottom: 1px solid red !important;
}
#groupedItemTbl td{
  padding: 10px;
  user-select: text;
  border: 1px solid rgba(255,255,255,0.3) !important;
}
#groupedItemTbl th{
  padding: 10px;
  border: 1px solid rgba(255,255,255,0.3) !important;
}
#groupedItemTbl thead tr{
  background-color: rgba(0, 0, 0, 0.3);
}
.fileStatusBlock{
  padding: 10px;
  color: #fff;
  border-radius: 10px;
}
.fileStatusBlock{
  p{
    margin: 0;
  }
}
.actionIcon{
  width: 22px;
  height: 22px;
  object-fit: contain;
  margin-right: 10px;
}
.spread-sheet {
  max-height: 55vh;
  overflow: auto !important;
  margin: 16px 0;


  tr:first-child .Spreadsheet__header:first-child {
    min-width: 50px;
    width: 50px;
  }

  tr:first-child th {
    position: sticky;
    top: 0px;
    background-color: #5f5f5f;
    color: #FFF;
    z-index: 2;
    text-align: left;
  }

  .data-viewer {
    width: 100%;
    height: 100%;

    &.error::after {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      border-bottom: 4px solid transparent;
      border-left: 4px solid transparent;
      border-top: 4px solid crimson;
      border-right: 4px solid crimson;
    }
  }

  .Spreadsheet__cell {
    position: relative;
  }

  .data-editor {
    .Spreadsheet__cell {
      padding: 0;
    }

    input {
      width: 100%;
      height: 100%;
      background-color: transparent;
      border: none;
      color: #000;

      &:active,
      &:focus {
        outline: none;
      }
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }

    .drop-down {
      position: absolute;
      z-index: 800;
      top: 100%;
      left: 0;
      min-width: 100%;
      margin-top: 1px;
      max-height: 250px;
      overflow: hidden auto;
      cursor: default;
      -webkit-box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
      box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
      background-color: #fff;
      padding-left: 0;

      li {
        list-style: none;
        list-style-type: none;
        user-select: none;
        font-size: 12px;
        line-height: 22px;
        white-space: nowrap;
        padding: 0 4px;
        color: #333;
      }
    }
  }
}

.maxHeight-uplif{
  max-height: 50vh !important;
}
.successMsg{
  // border: 1px solid green;
  padding: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  @include colors.primaryColor3;
  color: #fffdfd;
  border-radius: 8px;
}
.customSpreadSheetDropDown{
  background-color: #0a24339e;
  color: #fff;
  backdrop-filter: blur(11px);
  max-height: 25vh;
  position: absolute;

  ul{
    margin: 0;

    li{
      padding: 0.2rem 0.5rem;
      padding-bottom: 3px;
      border-bottom: 1px solid #ffffff4f;
      cursor: pointer;
      transition: all 0.2s;

      &:hover{
        background-color: #70CEEA;
        color: #000;
      }
    }
  }
}

.customSpreadsheetErr{
  width: 100%;
    p{
      margin: 0px;
    }
    ul{
      margin: 0;
      li{
        color: #000;
        font-style: italic;
      }
    }
}
.customSpreadsheetSuccess{
  p{
    margin: 0;
  }
}

.Spreadsheet__header{
  &:first-child{
    width: 100px;
  }
}
.pilferageCreate{
  height: 57vh !important;

  tr{
    &:first-child{
      .Spreadsheet__header{
        position: sticky;
        top: 0;
        background-color: #1d3e4e;
      }
    }
  }
}
.reconCreate{
  @extend .pilferageCreate;
}

.reversrGrnCreate{
  @extend .reconCreate;
  height: 37vh !important;
}
.letterOfIntetntCreate{
  @extend .reconCreate;
  height: 55vh !important;
}
.spin{
  animation: spin 1s linear infinite;
}
@keyframes spin {
  to{
    transform: rotate(360deg);
  }
}
.paymentSummeryText{
  font-size: 16px;
  font-weight: 700;
  margin:1rem 0;
  color: rgba(255, 255, 255, 0.7);
}
.sheetErrCell{
  transition: all 0.5s;
  border-bottom: 1px solid red !important;
}
.sheetCellErrMsg{
  position: absolute;
  // z-index: 2;
  left: 0;
  bottom:100%;
  color: red;
  font-size: 14px;
  opacity: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transition: all 0.3s;
  padding: 0.2rem;
}
.sheetErrCell:hover{
  .sheetCellErrMsg{
    opacity: 1;
    bottom: 0;
  }
}
.dropDownBtnArrowWrapper{
  position: relative;
  ul{
    opacity: 0;
    transform: scale(0);
    margin: 0;
    padding: 5px;
    position: absolute;
    top: 33px;
    left: -85px;
    background-color: #fff;
    z-index: 999;
    width: 150px;
    transition: all 0.3s;
    border-radius: 7px;

    li{
      margin: 0;
      padding: 0 3px;
      text-align: left;
      border-radius: 5px;
      &:hover{
      background-color: #add8e683;
      }
    }
  }
}
.dropDownBtnArrowWrapper:hover{
  ul{
    opacity: 1;
    transform: scale(1);
  }
}
.sales-orders-value{
    margin-top: 1.3rem;
    display: flex;
    justify-content: space-between;
    width: 50%;
}

.paddingLeft2{
  padding-left: 0.2rem;
}

.dashboard-message{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.cellClass.required{
  border-bottom: 1px solid red;
  position: relative;
}
.cellClass.error{
  border-bottom: 1px solid red;
  position: relative;
}
.cellClass.required:hover{
  &::before{
    content: "Required";
    position: absolute;
    top: 0;
    left: 0;
    color: #ffffff5d;
  }
}
