.flexRow{
    display: flex;
    flex-direction: row;

  }
.flex{
  display: flex;
}
  .flexCol{
    display: flex;
    flex-direction: column;
  }
  .flexWrap {
    flex-wrap: wrap;
  }
  .flexWrapNil {
    flex-wrap: nowrap;
  }
  .justifyContentCenter{
    justify-content: center;
  }

  .alignItemsCenter{
    align-items: center;
  }
  .alignItemsEnd{
    align-items: flex-end;
  }
  .alignItemsBaseline {
    align-items: baseline;
  }
  .justifyContentFlexStart{
    justify-content: flex-start;
  }
  .justifyContentFlexEnd {
    justify-content: flex-end;
  }
  .justifyContentSpaceBetween{
    justify-content: space-between;
  }
  .justifyContentSpaceEvenly {
    justify-content: space-evenly;
  }
  .justifyContentSpaceAround{
    justify-content: space-around;
  }
  .alignItemsFlexStart{
    align-items: flex-start;
  }
  .buttonWithMargin {
    margin-right: 10px;
  }
  .gap10{
    gap: 10px;
  }
  .gap20{
    gap: 20px;
  }