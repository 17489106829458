@use '../inner/mixins' as myTheme;
@use '../inner/color' as colors;
@use '../inner/flexclasses';
@use '../inner/typography';
@use '../inner/hierarchy';

.side-menu-container {
  width: 16%;
  min-width: 16%;

  transition: width 0.5s linear;
  height: calc(100vh - var( --top-header-height));
  overflow: auto;
  padding-bottom: 100px;

  @include myTheme.theme-aware('background', 'color-background');
  @include myTheme.theme-aware('color', 'color-foreground');

  // Required for later use .menu-list {overflow: hidden auto;}
  .menu-item {
    .icon-caret-down {
      opacity: 0;
      transform: rotate(-180deg);
      color: #70CEEA;
      font-size: 14px;
      transition: opacity 0.5s, transform 0.5s linear;
    }



    &.current {
      .sidebar-icon {
        @include myTheme.theme-aware('color', 'sideMenuColorActive-icon');
      }
      .menu-item-head, &:hover {
        @include myTheme.theme-aware('background', 'sideMenuActive-bg');
        @include myTheme.theme-aware('color', 'sideMenuActive-text');

        .icon-caret-down {
          opacity: 1;
          transform: rotate(-90deg);
        }
      }
    }

    .menu-item-head {
      text-decoration: none;
      padding: 5px 4px 5px 25px;
      transition: padding 0.5s linear;
      cursor: pointer;
      position: relative;
      @include myTheme.theme-aware('color', 'sideMenuColor-text');

      .icon-caret-right {
        opacity: 0;
        color: #70CEEA;
        position: absolute;
        right: 16px;
        transition: opacity 0.5s ease-in-out;
      }

      &.current {

        .icon-caret-right {
          opacity: 1;
        }

      }
      .title {
        max-width: 345px;
        overflow: hidden;
        white-space: nowrap;
        transition: max-width 0.5s linear;
      }
      &:hover, &:hover .sidebar-icon {
        @include myTheme.theme-aware('background', 'sideMenuActive-bg');
        @include myTheme.theme-aware('color', 'sideMenuActive-text');
      }

    }

    .sidebar-icon {
      @include myTheme.theme-aware('color', 'sideMenuColor-icon');
      font-size: 18px;
      margin-right: 16px;
    }

    .title {
      font-size: 0.875rem;
    }

    .sub-item-cont {
      overflow: hidden;
      @include myTheme.theme-aware('background', 'sideSubMenu-bg');
      transition: max-height 0.5s linear;

      .container {
        padding: 15px 16px 15px 72px;
      }
    }

    .sub-menu {
      font-size: 0.788rem;
      margin-bottom: 0.2rem;
      text-decoration: none;
      cursor: pointer;
      color: rgba(255, 255, 255, 0.86);
      &:hover,
      &.active {
        color: #FFF;
        font-weight: 800;
      }
    }
    .sub-menu:last-child{
        padding-bottom:0px;
    }


    &.active {
      .sub-item-cont {
        // max-height: 148px;
      }
      .menu-item-head {
        // .icon-caret-right {
        //   opacity: 0;
        // }
        .icon-caret-down {
          opacity: 1;
          transform: rotate(0deg)
        }
      }
    }
  }

  &.collapsed {
    width: 4.5%;
    min-width: 4%;

    .menu-item-head {
      padding-left: 30px;

      .title {
        max-width: 0;
      }
    }

    .menu-item {
      &.active {
        .icon-caret-down {
          opacity: 0 !important;
        }

        .icon-caret-right {
          opacity: 1;
        }
      }
    }
  }
}

.widgets-side-menu-container {
  width: 100%;
  transition: width 0.5s linear;
  overflow: overlay;
  height: 100%;
}

.dynamic-flag-menu-container {
  width: 100%;
  transition: width 0.5s linear;
  overflow: overlay;
  height: 50%;
}

.sidebar-icon {
  height: 24px;
  width: 24px;
  margin-right: 12px;
  margin-top: 7.9px;
  cursor: pointer;
  .footer-icon {
    height: 24px;
    width: 24px;
    padding-bottom: 7px;
    margin-right: 12px;
    margin-top: 60px;
  }
}

.width20 {
  width: 35px;
  margin-left: 25px;
}
.width20Galley{
  width:20%;
}
.link {
  height: 28px;
  display: flex;
  color: #FFFFFF;
  align-items: center;
  margin: 16px 24px;
  font-size: 20px;
  cursor: pointer;
  text-decoration: none;

  .static-text {
    margin-left: 40px;
    position: absolute;
    width: 737.5;
    height: 175;
    left: 862.5;
    top: 837.5;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
  }

  .footer-text {
    font-size: 12px;
    width: 88px;
    height: 14px;
    padding-top: 35px;
    padding-bottom: 48px;
    font-weight: 200;
    cursor: none;
    margin-top: 9.2em;

  }

}

.sideBarOptions {
  overflow-y: scroll;
  top: 0;
  bottom: 0;
}

.margTop24 {
  margin-top: 24px;
}

.profile {
  background-color: #FFFFFF;
  position: absolute;
  width: 237.5;
  height: 237.5;
  left: 562.5;
  top: 793.75;
}

.text {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  margin-left: 6px;
  margin-top: 8px;
  font-size: 15.3462px;
  line-height: 18px;
  color: #0D2636;
}

.margTop89 {
  margin-top: 99px;
}

.margTop51 {
  margin-top: 51px;
}

.marginTop40 {
  margin-top: 40px;

}
.marginTop30 {
  margin-top: 30px;

}

.marginTop53 {
  margin-top: 53.4px;

}

.header-logo {
  margin-left: 50px;
  margin-top: 20.81px;
  margin-bottom: 55px;
  cursor: pointer;
}

.linkWater-border {
  border: 1px solid #D0D5DD;
  border-radius: 5px;
}

.height100:hover {
  height: 100vh;
}

.logo {
  width: 115.9px;
  // margin-right: 98px;
  height: auto;
  left: 130.86px;
  top: 20.81px;
}

.footer-logo{
  width: 24.43px;
  height: 18px;
  left: 1046px;
  top: 1041px;
  margin-top: -2px;
}

.h5 {
  width: 97px;
  height: 24px;
  color: #FFFFFF;
  @extend .lato;
  font-weight: 300;
  margin-bottom: 40px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}

.icon_select {
  display: block;
  margin-left: 220px;
  position: relative;
  background-image: url('../../arrow.svg');
}

.highlight {
  display: flex;
  font-size: 20px;
  cursor: pointer;
  text-decoration: none;
  width: 245px;
  height: 62px;
  left: 0px;
  top: 215px;
  align-items: center;
  margin-left: 20px;
  color: #FFFFFF;
  background: #091b27;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);

  &:active,
  &:hover {
    color: #FFFFFF;
  }
}