@font-face {
  // font-family: 'icomoon';
  // src:  url('../fonts/design1/icomoon.eot?p11z7x');
  // src:  url('../fonts/design1/icomoon.eot?p11z7x#iefix') format('embedded-opentype'),
  //   url('../fonts/design1/icomoon.ttf?p11z7x') format('truetype'),
  //   url('../fonts/design1/icomoon.woff?p11z7x') format('woff'),
  //   url('../fonts/design1/icomoon.svg?p11z7x#icomoon') format('svg');

  // font-family: 'Linearicons-Free';
  // src:  url('../fonts/design2/Linearicons-Free.eot');
  // src:  url('../fonts/design2/Linearicons-Free.eot') format('embedded-opentype'),
  //   url('../fonts/design2/Linearicons-Free.ttf') format('truetype'),
  //   url('../fonts/design2/Linearicons-Free.woff') format('woff'),
  //   url('../fonts/design2/Linearicons-Free.svg') format('svg');

  font-family: 'icomoon';
  src:  url('../fonts/design3/icomoon.eot?p11z7x');
  src:  url('../fonts/design3/icomoon.eot?p11z7x#iefix') format('embedded-opentype'),
    url('../fonts/design3/icomoon.ttf?p11z7x') format('truetype'),
    url('../fonts/design3/icomoon.woff?p11z7x') format('woff'),
    url('../fonts/design3/icomoon.svg?p11z7x#icomoon') format('svg');


  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  // font-family: 'Linearicons-Free' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-eye:before {
  content: "\e9ce";
}
.icon-eye-blocked {
  content:"\e9d1"
}
.icon-expand-arrow:before {
  content: "\e912";
}
.icon-upload:before {
  content: "\e911";
}
.icon-edit:before {
  content: "\e904";
}
.icon-view:before {
  content: "\e910";
}
.icon-arrow-right:before {
  content: "\e900";
}
.icon-caret-down:before {
  content: "\f0d7";
}
.icon-caret-right:before {
  content: "\f0da";
}
.icon-cash-register:before {
  // content: "\e903";
  content: "\f0d6";
}
.icon-control-panel:before {
  content: "\e905";
}
.icon-dashboard:before {
  content: "\e901";
}
.icon-crew-menu:before {
  content: "\eb52";
}
.icon-loyalty-menu:before {
  content: "\eb18";
}
.icon-estimation:before {
  content: "\e907";
}
.icon-flights:before {
  content: "\e908";
}
.icon-inventory:before {
  content: "\ea78";
}
.icon-procurement-menu:before { 
  content: "\ea5d";
}
.icon-reports-menu:before {
  content: "\ea01";
  // content: "\e99a";
}
.icon-menu:before {
  content: "\eb2b";
}
.icon-operations:before {
  content: "\e90b";
}
.icon-products:before {
  content: "\e90c";
}
.icon-sales:before {
  // content: "\e99b";
  content: "\f080";
}
.icon-controlpanel:before {
  content: "\e995";
}
.icon-warehouse:before {
  content: "\e90e";
}
.icon-file-image:before {
  content: "\e90f";
}
.icon-file-empty:before {
  content: "\e924";
}
.icon-bin:before {
  content: "\e9ac";
}
.icon-cloud-download:before {
  content: "\e9c2";
}
.icon-plus:before {
  content: "\ea0a";
}
.icon-checkmark:before {
  content: "\ea10";
}
.icon-arrow-right2:before {
  content: "\ea3c";
}
.icon-file-pdf:before {
  content: "\eadf";
}
.icon-file-word:before {
  content: "\eae1";
}
.icon-file-excel:before {
  content: "\eae2";
}
.icon-employees:before {
  // content: "\e972";
  content: "\eb38";
}
.icon-configuration-pal:before {
  content: "\e994";
  // content: "\e9af";
}
.icon-configuration-akasa:before {
  content: "\eb6f";
}
.icon-configuration-mas:before{
  content: "\e9af";
}
.icon-configuration-flfy:before{
  content: "\ea99";
}

.icon-alerts:before {
  content: "\e9db";
}
.icon-submit:before {
  content: '\e926';
}
.icon-admin:before{
  content: "\e975";
}


.icon-btn{
  min-width: 24px;
  min-height: 24px;
}