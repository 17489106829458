@use './mixins' as myTheme;

/* main */
.catalinaBlue {
    color: #203354;
}

.catalinaBlue-bg {
    background-color: #203354;
}

.ziggurat {
    color: #7DA4B1;
}

.ziggurat-bg {
    background-color: #7DA4B1;
}

.chambray {
    color: #4C5C81;
}

.chambray-bg {
    background-color: #4C5C81;
}

.echoBlue {
    color: #9EAEC5;
}
.text-danger {
    color: #a94442;
}

.echoBlue-bg {
    background-color: #9EAEC5;
}

.darkBlue {
    color: #091b27;
}

.darkBlue-bg {
    background-color: #091b27;
}
.offBlueBg{
    background-color: #1d3e4e
}

.midnightExpress {
    color: #121D3A;
}

.midnightExpress-bg {
    background-color: #121D3A;
}
.midnightExpress2 {
    color: #000C2B;
}

.midnightExpress2-bg {
    background-color: #000C2B;
}

.black {
    color: #0E0E0E;
}

.black-bg {
    background-color: #0E0E0E;
}

.eastBay {
    color: #475467;
}

.eastBay-bg {
    background-color: #475467;
}

.white {
    color: #FFFFFF;
}

.white-bg {
    background-color: #FFFFFF;
}

/* ui elements color */

.lilac {
    color: #CC91C3;
}

.lilac-bg {
    background-color: #CC91C3;
}

.seSoir {
    color: #9D78AB;
}

.seSoir-bg {
    background-color: #9D78AB;
}

.echoBlue2 {
    color: #9CABD3;
}

.echoBlue2-bg {
    background-color: #9CABD3;
}

.logan {
    color: #9797B4;
}

.logan-bg {
    background-color: #9797B4;
}

.slateGrey {
    color: #6B7996;
}

.slateGrey-bg {
    background-color: #6B7996;
}

.kashmirBlue {
    color: #516186;
}

.kashmirBlue-bg {
    background-color: #516186;
}

.victoria {
    color: #565482;
}

.victoria-bg {
    background-color: #565482;
}


/* icons */

.ziggurat-icon {
    color: #7DA4B1;
}

.white-icon {
    color: #FFFFFF;
}

.midnightExpress-icon {
    color: #000C2B;
}
.midnightExpress-icon-bg {
    background-color: #000C2B;
}
.linkWater-clr {
    color: #203354;
}
.black-color {
    background-color: #FFFFFFC2;
}
@mixin backgroundColor {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.04)), #001B2B;
}
@mixin backgroundColor2 {
    background-color: #001B2B;
}
@mixin textColor {
    color: #FFFFFFC2;
}
@mixin textColor2 {
    color: #FFFFFF;
}
@mixin textRed {
    @include myTheme.theme-aware('color', 'highlightText-color');
}
/**
 * Theme colors defined in template
 */
@mixin primaryColor {
    @include myTheme.theme-aware('background-color', 'primaryColor-bg');
    @include myTheme.theme-aware('color', 'primaryColor-color');
}
@mixin secondaryColor {
    @include myTheme.theme-aware('background-color', 'secondaryColor-bg');
    @include myTheme.theme-aware('color', 'secondaryColor-color');
}
@mixin primaryColor2 {
    @include myTheme.theme-aware('background-color', 'primaryColor2-bg');
    @include myTheme.theme-aware('color', 'primaryColor2-color');
}
@mixin primaryColor3 {
    @include myTheme.theme-aware('background-color', 'primaryColor3-bg');
    @include myTheme.theme-aware('color', 'primaryColor3-color');
}
@mixin primaryColor4 {
    @include myTheme.theme-aware('background-color', 'primaryColor4-bg');
    @include myTheme.theme-aware('color', 'primaryColor4-color');
}
@mixin primaryColor5 {
    @include myTheme.theme-aware('background-color', 'primaryColor5-bg');
    @include myTheme.theme-aware('color', 'primaryColor5-color');
}
@mixin primaryColor6 {
    @include myTheme.theme-aware('background-color', 'primaryColor6-bg');
    @include myTheme.theme-aware('color', 'primaryColor6-color');
}
@mixin nuetralColor1 {
    @include myTheme.theme-aware('background-color', 'nuetralColor1-bg');
    @include myTheme.theme-aware('color', 'nuetralColor1-color');
}
@mixin nuetralColor2 {
    @include myTheme.theme-aware('background-color', 'nuetralColor2-bg');
    @include myTheme.theme-aware('color', 'nuetralColor2-color');
}
@mixin nuetralColorWhite {
    @include myTheme.theme-aware('background-color', 'nuetralColorWhite-bg');
    @include myTheme.theme-aware('color', 'nuetralColorWhite-color');
}

@mixin uiColor1 {
    @include myTheme.theme-aware('background-color', 'uiColor1-bg');
    @include myTheme.theme-aware('color', 'uiColor1-color');
}
@mixin uiColor2 {
    @include myTheme.theme-aware('background-color', 'uiColor2-bg');
    @include myTheme.theme-aware('color', 'uiColor2-color');
}
@mixin uiColor3 {
    @include myTheme.theme-aware('background-color', 'uiColor3-bg');
    @include myTheme.theme-aware('color', 'uiColor3-color');
}
@mixin uiColor4 {
    @include myTheme.theme-aware('background-color', 'uiColor4-bg');
    @include myTheme.theme-aware('color', 'uiColor4-color');
}
@mixin uiColor5 {
    @include myTheme.theme-aware('background-color', 'uiColor5-bg');
    @include myTheme.theme-aware('color', 'uiColor5-color');
}
@mixin uiColor6 {
    @include myTheme.theme-aware('background-color', 'uiColor6-bg');
    @include myTheme.theme-aware('color', 'uiColor6-color');
}
@mixin uiColor7 {
    @include myTheme.theme-aware('background-color', 'uiColor7-bg');
    @include myTheme.theme-aware('color', 'uiColor7-color');
}