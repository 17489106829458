    @use './mixins' as myTheme;
@use './color' as colors;
@use './flexclasses';
@use './typography';
@use './hierarchy';


table:not(.Spreadsheet__table):not(.custom-table) {
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #ddd;
    text-align: left;

    th, td {
      padding: 15px;
      border: 1px solid #ddd;
      text-align: left;
      user-select: text;
    }

    th:nth-child(2) {
      width: 430px;
    }
}

#mapping-table th:nth-child(2){
    width: auto;
}
#mapping-table td input.edit-input{
    width: 100%;
}

.ngo-grid{
    min-height: 200px;
}

.tableRowClass {
    @include myTheme.theme-aware('background-color', 'tableRowColor-bg');
}
.tableEvenRowClass {
    @include myTheme.theme-aware('background-color', 'tableEvenRowColor-bg');

}
.reports_button{
    @extend .grid-download-btnStyle1;
    color: #FFFFFF;
}

u {
    padding-bottom: .17rem;
    text-decoration:none;
    border-bottom:.3px solid #FFFF;
}

.selectFiltersBlock {
    height: 100%;
    padding: 1rem 2rem;
    border-radius: 10px;
    @extend .h5;

    .alert_error {
        @include myTheme.theme-aware('color', 'uiColor2-color');
    }
    .alert_success {
        @include myTheme.theme-aware('color', 'primaryColor3-color');
    }
}

.selectWidgetDashboard{
    @extend .selectFiltersBlock;
    padding: 13rem 2rem;
}
.ag-header, .ag-paging-panel, .ag-root {
    @include myTheme.theme-aware('background-color', 'tableColor-bg');
}

.ag-root-wrapper {
    border-radius: 0rem;
}

.table-header {
    @include myTheme.theme-aware('background', 'tableHeaderColor-bg');
    height: 1rem;
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem;

}

.table-pagination {
    border: 0.0625rem solid #FFFFFF;
    color: #FFFFFF;
    @include myTheme.theme-aware('background', 'color-background2');
    width: 2.188rem;
    height: 2.188rem;
    align-items: center;
    margin-top: .5rem;
}
.ag-cell {
    @extend .global-text-light;
    font-size: 0.7rem;
    white-space: normal;
}
.ag-header-cell-text {
    @extend .global-text-bold;
    font-size: 0.7rem;
    white-space: pre-wrap !important;
}
.ag-header-cell-label, .ag-header-cell-text {
    white-space: pre-wrap !important;
}

/**
 * Spreadsheet table style classes
 */
.Spreadsheet__table {
    width: calc(100% - 1px);

    th, td, .ag-cell {
        padding: 5px;
        white-space: pre-wrap !important;
    }
}

.custom-table{
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #ddd;
    text-align: left;

    th, td {
      padding: 15px;
      border: 1px solid #ddd;

      text-align: left;
    }

    th:nth-child(2) {
      width: 430px;
    }
}


  .custom-table-td td:first-child {
    border:none;
  }

/** === Grid buttons === */
.grid-download-btnStyle1 {
    @extend .fontMedium;
    cursor: pointer;
    line-height: 2;
    border-radius: 0.25rem;
    border: 1px solid;
    @include myTheme.theme-aware('border-color', 'formAddButtonColor-bg');
    background-color: transparent;
    padding: 0 10px;
    margin: 4px 0;
    &.failedCls {
        color: #e49170;
    }
    &.processCls {
        color: rgb(51, 204, 51);
    }
}
.grid-container-dashboard .ag-header-cell-text {
    font-size: 0.76rem;
    font-weight: 500;
    line-height: 1.2
  }
.grid-LOI-stylebutton-item{
    @extend .grid-download-btnStyle1;
    @include myTheme.theme-aware('border-color', 'secondaryColor-bg');
    cursor: pointer;
    padding: 5px;
}
.grid-LOI-stylebutton{
    @extend .grid-LOI-stylebutton-item;
    @include myTheme.theme-aware('border-color', 'primaryColor3-bg');
}
.grid-LOI-stylebutton-rem{
    @extend .grid-LOI-stylebutton-item;
    @include myTheme.theme-aware('background-color', 'formRemoveButtonColor-bg');
    @include myTheme.theme-aware('border-color', 'formRemoveButtonColor-bg');
}

.grid-LOI-stylebutton-fin{
    @extend .grid-LOI-stylebutton-item;
    @include myTheme.theme-aware('background-color', 'formInstanceControl-bg');
    @include myTheme.theme-aware('border-color', 'formInstanceControl-bg');
    cursor: pointer;
    padding: 0 5px;
    margin:0px !important;
}
.grid-LOI-stylebutton-fin-loi1{
    @extend .grid-LOI-stylebutton-item;
    @include myTheme.theme-aware('background-color', 'formInstanceControl-bg');
    @include myTheme.theme-aware('border-color', 'formInstanceControl-bg');
    cursor: pointer;
    padding: 5px;
    margin: 4px !important;
}

.grid-LOI-stylebutton-po{
    @extend .grid-LOI-stylebutton-item;
    @include myTheme.theme-aware('border-color', 'primaryColor3-bg');
    cursor: pointer;
    padding: 0 5px;
    margin: 0px;
}
.grid-LOI-stylebutton-po-suspend{
    @extend .grid-LOI-stylebutton-item;
    @include myTheme.theme-aware('background-color', 'formRemoveButtonColor-bg');
    @include myTheme.theme-aware('border-color', 'formRemoveButtonColor-bg');
    cursor: pointer;
    padding: 0 5px;
    margin: 0px;
}
.tblActionBtnsContainer {
    .failedCls {
        color: #e49170;
    }
}

.listGridSection {
    .cmpHeadingBlock {

        .cmpHeading {
            margin: 20px 0 20px 0;
        }

        .cmpHeadingRight {
            .chartLinkText {
                color: #70CEEA;
                cursor: pointer;
            }
            .icon-size {
                margin: 0 10px;
            }
            .icon-divider {
                width:1rem;
                height:2rem;
                margin-right: .3rem;
                margin-bottom: .2rem;
            }
        }
    }

    .ag-header-cell-text {
        white-space: pre-wrap !important;
        margin: 10px 0 20px 0;
    }
    .ag-cell {
        @extend .global-text-bolder;
        white-space: normal;
        font-weight: 300;
    }
}
.tableFilterBlocks {
    .leftBlock {
        .page-button {
            margin: auto;
        }
        .addBlk {
            margin-left: 1rem;
        }
    }
    .rightBlock {

    }
}

.cell-wrap-text{
    white-space: normal !important;
    line-height: 23px !important;
  }

.cellSelectionBlockItemCodes,
.cellSelectionBlockItemNames,
.cellSelectionBlockReasonTypes,
.cellSelectionBlockCurrencyCodes,
.cellSelectionBlockCurrencyNames
{
    display: none;
    position: absolute;
    top: 300px;
    left: 500px;
    background: white;
    padding: 0px 0px 10px 0;
    border-radius: 6px;
    min-width: 200px;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: scroll;


    ul {
        padding: 0px 0px;

        li {
            list-style-type: none;
            padding: 8px 10px;
            color: black;
            font-family: "Lato", sans-serif;

            &:hover {
                background: rgb(243, 191, 191);

            }
        }
    }
}

.cellSelectionBlockCurrencyCodes,
.cellSelectionBlockCurrencyNames
{
    ul{
        margin: 0;
        li{
            padding: 5px 5px !important;
            background-color: #dbd8d86f;
            margin-bottom: 2px;
            cursor: pointer;

            &:hover{
                background-color: #70ceeaac;
                color: #000;
            }
        }
    }
}

#myGrid {
    .ag-root-wrapper {
        border: 0px solid transparent;
    }
    .ag-body-horizontal-scroll-viewport {
        // width: auto !important;
        overflow-x:auto !important;
      }
    .ag-row-first {
        border-top: gray !important;
    }
   .ag-row {
       border-bottom: gray;
       border-left: none;
       border-right: none;
   }
   .ag-cell-value {
       font-size: 12px;
   }

}

.ag-horizontal-left-spacer, .ag-horizontal-right-spacer {
    overflow-x: auto !important;
}

.spl-row-class {
    @include colors.uiColor7;
}
.dfp_calander_sales_table td{
  padding: 5px !important;
}
.dfpTable{
    th{
        border:none !important;
    }
}