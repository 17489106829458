

/* captions */
.tiny-caption-regular {
  font-weight: 400;
  font-size: 0.375rem;
  line-height: 0.8125rem;
}

.caption-light {
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 0.875rem;
}

.caption-regular {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.875rem;
}

/* buttons */
.small-button-text-regular {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.normal-button-text-regular {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.1875rem;
}

.large-button-text-medium {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.1875rem;
}

/* body */
.small-body-text-regular-subscript {
  font-weight: 400;
  text-align: right;
  color:#D7A31A;
  font-size: calc(0.05rem + 0.6vw);
  line-height: 1.1875rem;
}
.small-body-text-regular-label {
  font-weight: 400;
  text-align: right;
  font-size: calc(0.05rem + 0.4vw);
  line-height: 1.1875rem;
}
.small-body-text-regular-label-upload {
  font-weight: 400;
  text-align: left;
  font-size: calc(0.18rem + 0.6vw);
  line-height: 1.1875rem;
}
.small-body-text-regular-login {
  font-weight: 400;
  line-height: 1.25rem;
  font-size: calc(0.3rem + 0.5vw);
}

.remember-days {
  display: flex;
  align-items: center;
}
.text-login-forgot-password {
@extend .small-body-text-regular-login;
  color: #005B97;
  text-decoration: none;
}

.small-body-text-regular-imp {
  font-weight: 400;
  text-align: left;
  color: red;
  font-size: calc(0.05rem + 0.8vw);
  line-height: 1.1875rem;
}
.small-body-text-regular {
  font-weight: 400;
  font-size: calc(0.1rem + 1vw);
  line-height: 1.1875rem;
}


.small-body-text-regular-vendor {
@extend .small-body-text-regular;
margin-left: 5.5rem;
}
.small-body-text-regular-note {
  font-weight: 470;
  font-size: calc(0.1rem + 0.7vw);
  line-height: 1.1875rem;
}
.small-body-text-regular-checkbox {
  @extend .small-body-text-regular;
  margin-top: .625rem;
}
.small-body-text-light {
  font-weight: 400;
  font-size: calc(1vw - 0.2rem);
  line-height: calc(1vw - 0.5rem);
}
.body-text-regular {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.1875rem;
}

.body-text-extrabold {
  font-weight: 800;
  font-size: 1.125rem;
  line-height: 1.375rem;
}

/* sub-heading */
.sub-heading {
  font-size: 1.1rem;
  line-height: 1.5rem;
}
.sub-heading-title {
  font-size: .95rem;
  line-height: 1.5rem;
  margin-top: .25rem;
}

.sub-heading-title-req{
  margin-top: 1.3rem;
}
.subheading-text-regular {
  font-weight: 400;
  @extend .sub-heading;
}
.subheading-text-medium-title {
  font-weight: 400;
  @extend .sub-heading-title;
}

.subheading-text-small-title {
  font-weight: 400;
  font-size: 0.9rem;
  @extend .sub-heading-title;
}

.subheading-text-medium {
  font-weight: 500;
  @extend .sub-heading;
}

.subheading-text-semibold {
  font-weight: 600;
  @extend .sub-heading;
}

/* heading */
.h6 {
  font-weight: 400;
  font-size: 1.075rem;
  line-height: 1.625rem;
}
.h5 {
  font-weight: 400;
  font-size: 1.175rem;
  line-height: 1.625rem;
}
.h4 {
  font-weight: 400;
  font-size: 1.375rem;
  line-height: 1.625rem;
}

.h3 {
  font-weight: 600;
  font-size: 1.625rem;
  line-height: 1.9375rem;
}

.h2 {
  font-weight: 400;
  font-size: 1.75rem;
  line-height: 2.125rem;
}

.h1 {
  font-weight: 500;
  font-size: 2.5rem;
  line-height: 3rem;
}

/* miscellaneous */
.login-sub {
  font-size: 1.375rem;
  line-height: 2.0625rem;
}

.supporting {
  font-size: 1.125rem;
  line-height: 1.375rem;
}
.dropdown-heading {
  line-height: 31px;
  font-size: calc(0rem + 1vw)
}
.dropdown-text {
  line-height: 19px;
  font-size: calc(0.1rem + 1vw)
}

@media (min-width: 600px) {
    .dropdownStyle1-TextMedium {
        font-weight: 500;
        font-size: calc(0.005rem + 1vw);
        line-height: calc(0.6rem + 1vw);
    }
  }

  @media (min-width: 1700px) {
    .dropdownStyle1-TextMedium {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
    }
  }


.heading-text {
  line-height: 31px;
  font-size: calc(0.3rem + 1vw)
}
.card-heading-text {
  line-height: 24px;
  font-size: calc((0.3rem + 1vw) * 0.8);
}
.card-value-text {
  line-height: 33.6px;
  font-size: calc(0.6rem + 1vw);
}
.card-footer-text {
  line-height: 21.6px;
  font-size: calc(0.01rem + 1vw);
}
.dialog-title-text {
  font-size: calc(0.3rem + 1vw);
  line-height: calc(0.7rem + 1vw);
  font-style: normal;
}
.global-text-light {
  font-weight: 400;
  font-size: calc(1vw - 0.15rem);
  line-height: 1.1875rem;
}
.global-text-regular {
  font-weight: 400;
  font-size: calc(1vw + 0.09rem);
  line-height: 1.1875rem;
}
.global-text-bold {
  font-weight: 500;
  font-size: calc(1vw - 0.11rem);
  line-height: 1.1875rem;
}
.global-text-bolder {
  font-weight: 800;
  font-size: calc(1vw - 0.1rem);
  line-height: 1.1875rem;
}
/* DASHBOARD GRID STYLE */
.gridlist-text-bold {

  // font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 600;
  font-size: calc(1vw + 0.2rem);
  line-height: 1.1875rem;
  /* identical to box height, or 133% */

  letter-spacing: -0.006em;
}

.line-height-none {
  line-height: none !important;
}
