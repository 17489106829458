@use './inner/mixins' as myTheme;
@use './inner/color' as colors;
@use './inner/flexclasses';
@use './inner/typography';
@use './inner/hierarchy';

.login-bg {
  background: url('/images/login/loginBg.png');
}
.color {
  @include myTheme.theme-aware('color', 'color-foreground');
}

.center {
  align-items: center;
  text-align: center;
}

.btn-link {
  cursor: pointer;
  text-decoration: none;
  
  @extend .fontMedium;
  @extend .midnightExpress-icon;

  &:focus,&:hover {
    @extend .linkWater-clr;
  }
  &:focus {
    box-shadow: none;
  }
}
.input-field,.input-field-email{
  padding: 1rem !important;
}

