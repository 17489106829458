.flexMenu{
    display: flex;
    text-align: left;
     align-items: center;
}

.width100Menu{
    width:100%
}

.width90Menu{
    width: 95%;
}

.dialogMenu{
    height: -webkit-fill-available ;
    width: -webkit-fill-available;
    position: absolute;
    background: #001B2B ;
    margin-top:-24px;
    // padding-bottom: 40px;
}

.dialogMenu-caterer{
    max-width: 100%;
    width: 80vw;
    height:90vh;
    margin:-24px 0px 0px -12px;
    @extend .dialogMenu;
    background:#162f3e,

}

.imgCloseMenu{
    width:16px;
    height: 16px;
    cursor: pointer

}

.css-166bipr-Input{
    color:white !important
}

.selectMenu{
    width:200px !important;
    margin-top:12px;
    margin-bottom: 8px;
    min-width:165px !important;
}
.selectMenu-meals{
    @extend .selectMenu;
    margin: 4px 0px 0px 0px;
}
.select-menu-dfp-filter{
    @extend .selectMenu-meals;
    max-width: 200px;
    width:180px !important;
}

.selectMenu-audit{
    @extend .selectMenu-meals;
    margin: 2px 0px 0px 0px;
    padding: 0.2rem;
}

.selectMenu-meals-master{
    @extend .selectMenu;
    right:6%;
}
.selectMenu-meals-reconcilled{
    @extend .selectMenu;
    margin: 0px 0px 0px 0px;
}
.inputWidthMenu{
    width:165px !important;
    margin-right:8px;

}

.css-13cymwt-control, .css-t3ipsp-control, .css-1nmdiq5-menu, .css-1nmdiq5-menu div,.css-1nmdiq5-menu div:hover {
    background-color: #172f3e !important;
    cursor:pointer
}

.css-1dimb5e-singleValue{
    color: whitesmoke !important;
}

.ag-row-odd,.ag-row-even {
    background:none  !important;
    border: none;;
}

.width30Menu{
    width: 30%;
    white-space: nowrap;
}

.width80Menu{
    width:80%;
}
.width20Menu{
    width:20%;
    white-space: nowrap;
}
.saveBtnMenu{
    display: flex;
    justify-content: center;
}

.overlay{
    top: 90px !important;
    background:none !important;
    border-radius: 4px;

}

.menuLeft{
    text-align: left !important;
}

.dialog__content{
    margin-top: 24px;
}

.dialog__footer{
    padding-bottom:0px !important;
}

.spaceBetweenMenu{
    justify-content: space-between;
}

.flexColMenu{
    display: flex;
    flex-direction: column;
}

.margBotMenu{
    margin-bottom: 8px;
}

.menu div {
    width: 30%;
}

.margTop0{
    margin-top:0px !important;
}

.textAreaMenu{
    margin-top:16px;
    background:none;
    border:1px solid white;
    border-radius: 4px;
    padding:8px;
    margin-right:24px;
}

.dialog{
    margin-top:-10px  !important;
}
.text-wrap{
    white-space:normal
}