@use './inner/mixins' as myTheme;
@use './inner/color' as colors;
@use './inner/flexclasses';
@use './inner/typography' as typography;
@use './inner/hierarchy';
@use './inner/top-header';
@use "./icon.scss";



/** Control panel changes **/
.ctrl-container {
  --column-1-width: 25%;
  --column-2-width: 40%;
  .close-icon{
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
  .ctrl-card {
    border: 1px solid;
    border-radius: 4px;

    .ctrl-caption {
      @include colors.secondaryColor;
      // @include myTheme.theme-aware('color', 'color-foreground');
      padding: 0.2rem 0.5rem;
    }

    .ctrl-filters {
      padding: 0.2rem 0.5rem;
      border: 1px solid;
      display: flex;
      flex-direction: row;

      .devider {
        width: 1px;
        height: 50%;
        margin: auto 0;
        background-color: #c1c1c1;
      }

      .filterItem {
        display: flex;
        flex-direction: column;
        padding: 5px;

        // &::after {
        //     position: absolute;
        //     content: ' ';
        //     width: 1px;
        //     height: 50%;
        //     top: 25%;
        //     right: 1px;
        //     border-left: 1px solid #6e6b6b;
        // }

        .fltrItmContent {

          // border-right: 1px solid #6e6b6b;


          .viewBtnBlk,
          .openBtnBlk {
            // display: flex;
            // margin: 0 1.6rem 0 0;
            // padding: 0.5rem 1.2rem 0.2rem 0.5rem;


            .viewBtn,
            .openBtn {

              // padding: 0.5rem 1.2rem 0.2rem 0.5rem;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              color: inherit;
              padding: 10px;
              margin: 1px;

              &.show {
                background-color: #0f3b46;
                border-radius: 4px;
              }

              .fltrItmIconBlk {
                min-height: 30px;

                img {
                  max-width: 22px;
                  height: auto;
                }
              }

            }
          }



          .controlViewsPanel {
            // display: none;
            display: flex;
            position: absolute;
            z-index: 100;
            background-color: #0f3b46;
            border-radius: 4px;
            padding: 1.2rem;
            box-shadow: 2px 2px 1px 0px #889ba0;

            .dropdown {
              background-color: #0f3b46;
              border-radius: 4px;

            }

            .openBtn {
              background-color: #FFF;
              padding: 0.3rem;
              max-width: 150px;
              border-radius: 2px;
            }
          }

        }

        .fltrItmFoot {
          // padding: 0.2rem 0.2rem 0.2rem 0rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }

    }

    .ctrl-card-content {
      display: flex;
      flex-direction: row;
      height: calc(100vh - (var(--topBarHeight) + 270px));
      // overflow-x: auto;
      // overflow-y: auto;

      .ctrl-card-content-sub-1 {
        padding: 0.5rem;
        // border-right: 1px solid;
        width: var(--column-1-width);
        min-width: 184px;
        overflow-x: auto;
        overflow-y: scroll;

        img {

          max-width: 13px;
          height: auto;
          margin-right: 1px;
        }

        .groupNameList {


          details {


            .mySummary {
              display: flex;
              flex-direction: row;
              align-items: center;
            }

            .grp_content_level_1 {
              margin-left: 24px;

              .grp_content_level_2 {
                margin: 0px;
                padding: 0;

                ul {
                  padding: 0px 10px 0px 10px;
                  margin: 5px 0px 0px 5px;

                  li {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;

                    &:hover {
                      background-color: #0f3b46;
                      cursor: pointer;
                    }
                  }

                }
              }
            }

          }

        }
      }

      .ctrl-card-content-sub-2 {
        display: grid;
        padding: 0px;
        // border-right: 1px solid;
        width: var(--column-2-width);
        min-width: 442px;
        // overflow-x: auto;
        // overflow-y: scroll;
        // @include myTheme.theme-aware('background-color', 'nuetralColorWhite-bg');

        .selectGroupMessage {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        .tabsHeading {

          ul.tabsList {
            padding: 0px;
            margin: 0px;

            li {
              padding: 3px 5px 5px 5px;
              margin: 0;

              display: inline-flex;
              justify-content: center;
              align-items: center;

              &.activeItem {
                // @include myTheme.theme-aware('background-color', 'cardBorderColor');
                // @include colors.uiColor4;
                background-color: #0f3b46;
              }
            }

          }


          .title {
            display: flex;
            // width: 100%;
            // @include myTheme.theme-aware('background-color', 'cardBorderColor');
            // @include colors.uiColor4;
            background-color: #0f3b46;
            @extend .caption-light;
            padding: 0.5rem 0.6rem;
          }

        }

        .tabsContent {
          // height: max-content;
          // height: calc(100vh - (var(--topBar\Height) + 270px));
          overflow-x: auto;
          overflow-y: scroll;

          display: flex;
          flex-direction: column;

          .tabsInnerContent {
            // @include myTheme.theme-aware('background-color', 'uiColor3-bg');

            display: flex;
            flex-direction: column;

            // img {

            //   max-width: 13px;
            //   height: auto;
            //   margin-right: 1px;
            // }


            .groupTitle {
              @extend .h4;
              padding: 0.5rem;

              display: flex;
              flex-direction: row;
              align-items: center;

              .txthighlight {
                @include colors.textRed;
              }
            }

            .ctrl-group-list-items {
              display: flex;
              flex-direction: column;

              ol {
                padding: 0px;
                // margin: 0px;
              }

              li {
                list-style-type: disc;
                padding: 5px 0px 5px 0px;
                margin: 8px 5px;
                // box-shadow: 1px 2px 2px 2px rgba(0, 3, 112, .4);
                box-shadow: 0px 1px 7px rgb(8 0 14 / 86%);
                box-sizing: content-box;

                &:hover {
                  transform: translateY(-2px);
                  box-shadow: -8px 4px 17px rgb(8 0 14 / 93%);

                }

                table {
                  border: none;

                  tr {
                    td {
                      display: flex;
                      border: none;
                      padding: 0px 10px 6px 10px;


                      img {
                        width: 15px;
                        height: auto;
                      }



                    }

                    .group-item-titleaction {

                      .downloadOptions {
                        display: inline-flex;
                        margin: 0 0 0 10px;

                        img {
                          width: 23px;
                          height: auto;
                          border: 1px solid;
                          padding: 3px;

                          &:hover {
                            @include colors.primaryColor3;
                          }
                        }

                      }
                    }

                    .group-item-route {
                      @extend .h6;

                      .txthighlight {
                        margin-left: 10px;
                        @include colors.textRed;
                      }
                    }

                    .group-item-counts {
                      span {
                        @extend .subheading-text-small-title;
                        margin-right: 10px;
                      }
                    }

                    .group-item-orders {
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                      align-items: center;

                      img {
                        width: 25px;
                        height: auto;
                      }
                    }

                  }
                }
              }

            }

          }
        }

      }

      .ctrl-card-content-sub-3 {
        display: grid;
        padding: 0px;
        width: calc(100% - var(--column-1-width) - var(--column-2-width));

        .heading {
          display: flex;
          flex-direction: column;
          align-items: center;
          @extend .h4;
          margin: 2rem 0rem 1rem 0rem;
        }

        .selectCategoryMessage {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        .section2details {
          overflow-x: auto;
          overflow-y: scroll;

          display: flex;
          flex-direction: column;
          width: 100%;

          ul {
            margin: 0;
            padding: 0 0 10px 0;
            border-top: 1px solid;

            &:last-child {
              border-bottom: none;
            }

            li {
              padding: 10px 20px;
              // @include colors.primaryColor3;
              border-bottom: 1px solid;

              &:last-child {
                border-bottom: none;
              }

              &:hover {
                @include colors.primaryColor3;
              }
            }
          }
        }

      }

    }

    .ctrl-card-footer {
      display: flex;
      padding: 0.5rem;
      flex-direction: row;
      justify-content: space-between;
      border-top: 1px solid;

      .item {
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        opacity: 0.5;
        @extend .small-body-text-light;
      }

      img {

        max-width: 13px;
        height: auto;
        margin-right: 3px;
      }
    }

  }

  .hide {
    display: none;
  }

  .view-sections {
    .tree-node {
      cursor: pointer;

      .node-icon {
        margin-right: 8px;

        &.svg {
          width: 16px;
          height: 16px;
        }

        &.font {
          font-size: 16px;
          transition-duration: 0.1s;
          transition-property: transform;
          transform: rotate(0deg);

          &.open {
            transform: rotate(90deg);
          }
        }
      }
    }

    .list {
      width: 23%;
      height: 100%;
      padding: 8px;
      overflow: hidden;

      .tree {
        min-width: 100%;
        overflow: hidden;
      }
    }

    .resizer {
      width: 2px;
      height: 100%;
      background-color: #FFF;

      &:hover {
        cursor: col-resize;
      }
    }

    .content {
      width: 60%;
      height: 100%;
    }

    .not-synced {
      padding: 4px;
      height: 24px;
      margin: 0;
      line-height: 1;
      align-self: flex-end;
      position: absolute;
      bottom: 8px;
      right: 8px;
    }

    .intent {
      cursor: pointer;
    }

    .btn-proceed {
      width: 180px;
    }

    .emaildownload {
      margin-left: 0.8rem;

      .btn {
        border-color: #FFF;
        padding: 4px 8px;

        &:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        &:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }

        .icon:hover {
          fill: red;
        }
      }
    }

    .alerts {
      width: 25%;
      height: 100%;
      border-left: 1px solid #FFF;
      padding: 6px 8px;

      .title {
        height: 32px;
      }

      .estimate {
        background-color: #fff;
        border: 1px solid #ddd;
        padding: 10px 15px;

        &:first-child {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }

        &:last-child {
          margin-bottom: 0;
          border-bottom-right-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }
    }
  }

  .card-list {
    overflow: auto;
  }

  .dfp-modal {
    top: calc(1px + var(--top-header-height));
    left: 20%;
    width: 100%;
  }

  .title-chip {
    min-width: 10px;
    padding: 3px 7px;
    color: #777;
    white-space: nowrap;
    background-color: #FFF;
    border-radius: 10px;
    margin-left: 8px;
  }

  .cnt-tabs {
    margin-left: 8px;

    .tab {
      margin-right: 16px;
      padding: 4px 8px;

      &.active {
        padding: 6px;
        border: none;
        margin: 0.5px;
        background-color: #70CEEA;
        color: #000;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        margin: 0px 0.5px;
      }
    }
  }

  .pad {
    padding: 16px;
  }

  .breadcrumb {
    width: 100%;
    background-color: #FFF;
    color: #0f3b46;
    padding: 0 8px;
    margin-bottom: 0.2rem;

    .br-icon {
      font-size: 12px;
      margin: 0 8px;
    }
  }

  .carter-card {
    all: unset;
    border-radius: 4px;
    border: 1px solid #eee;
    padding: 8px;
    transition: all 0.2s ease-in-out;
    max-width: 100%;
    margin-bottom: 8px;
    position: relative;

    .heading {
      cursor: pointer;

      .sl-no {
        width: 32px;
      }

      .crop {
        margin-right: 16px;
      }
    }

    .cater-table {
      margin-top: 8px;
      transition: height 0.25s;
      height: 0;
      overflow: hidden;

      &.open {
        height: 150px;
      }
    }
  }
}

.ctrl-calender-container {
  .icon-undo{
    width: 48px;
    height: 38px;
    margin-left: 16px;
    margin-bottom: 0.7rem;
    border: 1px solid #DDD;
    border-radius: 4px;
    img{
      width: 80%;
      height: 80%;
    }
    &:disabled{
      opacity: 0.5;
    }
  }
  .date-input{
    .react-datepicker__tab-loop{
      z-index: 5;
      position: relative;
    }
  }
  .o-hide {
    overflow: hidden;
  }
  .group-by{
    margin-left: 16px;
  }
  .group-by .dialog__description{
    display: flex;
    flex-direction: column;
  }
  .tabl-cntr {
    position: relative;
    overflow: auto;

    /* width */
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      border-radius: 5px;
    }

    /* Show scroll bar on Firefox browser */
    &::-moz-scrollbar {
      width: 5px;
      height: 5px;
      border-radius: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, .5);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgba(105, 103, 103, 0.75);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(105, 103, 103, 0.95)
    }
  }
  .tabl-cntr.dynamicBorder{
    border:1px solid #ddd;
    border-top: none;
  }

  .tbl {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    th {
      width: 72px;
      padding: 0px !important;
      text-align: center;
      position: sticky;
      top: 0;
      background-color: #1d3e4e;
      color: #FFF;
      z-index: 2 !important;
      border: none;
      &.fixed {
        z-index: 3 !important;
        text-align:center ;
      }
      .head-cell{
        font-size: 0.75rem;
        padding: 8px;
        border:1px solid #ddd;
        height: 6vh;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    td {
      border: none !important;
      text-align: center !important;
      height: 84px;
      z-index: 0;
    }

    tr {
      position: relative;
    }

    .group-cell {
      border: none !important;
      padding: 0;
      .cell{
        width: 100%;
        height: 100%;
        border: 1px solid #001B2B !important;
      }
    }

    .time-cell {
      border-bottom: none !important;
    }
  }
  .tblDfp{
    border:none !important;
  }

  .fixed {
    position: sticky;
    left: 0;
    z-index:2 !important;
    color: #FFF;
    width: 80px !important;
    text-align: center;
    background-color: #1d3e4e;
    font-size: 0.75rem;

    &.tail {
      left: 80px !important;
    }
  }

  .tail,
  .station {
    cursor: pointer;
  }

  .flight {
    background-color: #70CEEA !important;
    position: absolute;
    top: 8px;
    color: rgb(0, 0, 0);
    padding: 2px 8px;
    font-size: 13px;
    overflow: hidden;
    cursor: pointer;
    text-align: center;
    z-index: 1;
    border-radius:5px 5px 0px 0px;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.cat-group {
      background-color: #1c3c4c !important;
      top: 40px;
      border-radius:0px 0px 5px 5px;
      color: #eee;
    }
  }

  .info-table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;

    thead {
      display: none;
    }

    td {
      border: 1px solid #DDD;
    }
  }
}
.ctrl-container-merge-menu{
  position: absolute;
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #0D2636 !important;
  z-index: 11;
  ul{
    margin: 0;
    padding: 0;
  }
  li{
    padding: 2px 8px;
    min-width: 200px;
    border-bottom: 0.5px solid rgba(0, 27, 43, 0.38);
    cursor: pointer;
    color: #0D2636 !important;
    &:hover{
      background-color: #DDD;
    }
  }
}
.cntrl-calender-catering{
  margin-top: 24px;
  .cnt-tabs{
    border-bottom: 3px solid #70CEEA !important;
    height: 36px;
    width: 100%;
  }
  .left-bloc{
    height: 75vh;
    width: 30%;
    overflow-y: scroll;
    padding: 15px;
    border-right: 1px solid #DDD;
}
  .right-bloc{
    height: 100%;
    width: 75%;
    padding: 16px;
    .cont{
      padding: 0 4px;
      [col-id="0"]{
        padding-left: 8px;
      }
    }
  }
  .uplifts{
    .left-bloc{
      width: 30%;
    }
    .right-bloc{
      width: 70%;
    }
  }
  .items{
    .right-bloc{
      width: 100%;
    }
  }
}
.cntrl-calender-summary{
  padding: 16px;
  max-height: 75vh;
  overflow-y: auto;
  .summary-tbl{
    table-layout: fixed;
    border: none;
    thead{
      // display: none;
    }
    th{
      width: 90% !important;
      border: none;
    }
    th:first-child{
      width: 180px !important;
    }
    tr td:first-child{
      vertical-align: baseline;
    }
    td{
      border: none;
      user-select: text;
    }
    .sub-table{
      width: 40%;
      border: none;
      margin-top: 12px;
      th{
        table-layout: fixed;
        border: none;
        width: 15px !important;
        padding: 0px 4px;
      }
    }
  }
}
.cntrl-calender-summary td:first-child {
  font-size: 0.8rem;
  user-select: text;
  width: 80px;
  background-color: #1d3e4e;
  color: #fff;
}
.sub-table thead th {
  background-color: #1d3e4e;
  color: #fff;
}
